import { MailOutlined } from "@ant-design/icons";
import { Tabs, Button, DatePicker, Modal, Row, Select, Col } from "antd";
import AppInput from "components/AppInput";
import moment from "moment";
import React, { useEffect } from "react";
import CreateTable from "./CreateTable";
import Owner from "./Owner";

const { Option } = Select;
const { TabPane } = Tabs;
const children = [];

for (let i = 10; i < 36; i++) {
  children.push(
    <Option className={"tags"} key={i.toString(36) + i}>
      {i.toString(36) + i}
    </Option>
  );
}

export default function CreatePlanModal({
  visible,
  ownerText,
  handleChange,
  name,
  start_line,
  dead_line,
  description,
  purpose,
  loading,
  handleCreatePlan,
  active,
  inviteModal,
  inviteEmail,
  users,
  invited_owner,
  handleInvite,
  loadingInvite,
  userEmail,
  onClose,
}) {
  const [endDateError, setEndDateError] = React.useState(false);

  useEffect(() => {
    if(dead_line && start_line > dead_line){
      setEndDateError(true);
    } else {
      setEndDateError(false);
    }
  }, [start_line, dead_line]);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const truncateEmail = (email) => {
    if (email && email.length > 30) {
      return `${email.substring(0, 28)}...`;
    }
    return email;
  };
  return (
    <>
      <Modal
        footer={null}
        // closeIcon={false}
        // closable={false}
        width={active === "2" ? "90%" : 500}
        onCancel={onClose}
        title="Create New Plan"
        className="modalStyle"
        open={visible}
      >
        {/* <Tabs defaultActiveKey='1' onChange={key => handleChange('active', key)}>
        <TabPane tab='Action items' key='1'> */}
        <>
          <Row align="middle" className="mb-2">
            <Col xs={24} md={8}>
              <div>Project Name*</div>
            </Col>
            <Col xs={24} md={16}>
              <AppInput
                placeholder={"Enter a project name"}
                value={name}
                maxLength={255}
                name={"name"}
                onChange={handleChange}
                // label={'Project name'}
              />
            </Col>
          </Row>
          {/* <div>
        <label className='inputLabel'>Project area</label>
        <Select
          mode='tags'
          size={'large'}
          className='fullSelect mb-2 mt-1'
          placeholder='Please select'
          defaultValue={['a10', 'c12']}
          onChange={handleChange}
          style={{
            width: '100%'
          }}
        >
          {children}
        </Select>
      </div> */}
          <Row align="middle" className="mb-2">
            <Col xs={24} md={8}>
              <div>Project Owner</div>
            </Col>
            <Col xs={24} md={16}>
            <Select
    className={invited_owner ? "fullSelect PlanSelectActive" : "fullSelect PlanSelect"}
    size="large"
    style={{ width: "100%" }}
    showSearch
    notFoundContent={
      validateEmail(ownerText) ? (
        <Button
          type="primary"
          onClick={() => {
            handleChange("inviteModal", true);
            handleChange("inviteEmail", ownerText);
            handleChange("invited_owner", ownerText);
          }}
        >
          Invite {truncateEmail(ownerText)}
        </Button>
      ) : (
        "No results. Enter email to invite"
      )
    }
    onSearch={(e) => handleChange("ownerText", e)}
    placeholder="Select an owner"
    value={truncateEmail(invited_owner)} // Applyed the truncation here
    onChange={(value) => handleChange("invited_owner", value)}
    dropdownRender={(menu) => <Owner menu={menu} />}
  >
                {users?.map((owner, index) => (
                  <Option key={index} value={owner?.email}>
                    {owner?.email}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <Row align="middle" className="mb-2">
            <Col xs={24} md={8}>
              <label className="inputLabel">Start Date*</label>
            </Col>
            <Col xs={24} md={16}>
              <DatePicker
                className="fullSelect"
                style={{ fontSize: 14, height: 40, width:"100%"}}
                value={start_line}
                /* disabledDate={(current) => {
                  let customDate = moment().format("YYYY-MM-DD");
                  return (
                    (current && current > moment(dead_line, "YYYY-MM-DD")) ||
                    (current && current < moment(customDate, "YYYY-MM-DD"))
                  );
                }} */
                name={"start_line"}
                onChange={(date) => handleChange("start_line", date)}
                placeholder="YYYY-MM-DD"
              />
            </Col>
          </Row>
          <Row align="middle" className="mb-2">
            <Col xs={24} md={8}>
              <label className="inputLabel">End Date*</label>
            </Col>
            <Col xs={24} md={16}>
              <DatePicker
                placeholder="YYYY-MM-DD"
                
                className="fullSelect"
                disabledDate={(current) => {
                  // let customDate = moment().format("YYYY-MM-DD");
                  return (
                    (current && current < moment(start_line, "YYYY-MM-DD"))/*  ||
                    (current && current < moment(customDate, "YYYY-MM-DD")) */
                  );
                }}
                value={dead_line}
                name={"dead_line"}
                status={
                  moment(start_line).isAfter(moment(dead_line)) ? "error" : ""
                }
                style={{ fontSize: 14, height: 40 ,width:"100%" }}
                onChange={(date) => handleChange("dead_line", date)}
              />
          {endDateError && <p style={{ color: 'red' }}>The start date should be earlier than the end date.</p>}
            </Col>
          </Row>
          {/* <Row align='middle' className='mb-2'>
            <Col xs={24} md={8}>
              <label className='inputLabel'>Purpose*</label>
            </Col>
            <Col xs={24} md={16}>
              <AppInput
                placeholder={'Enter a purpose'}
                value={purpose}
                name={'purpose'}
                onChange={handleChange}
              />
            </Col>
          </Row> */}
          {/* <label className='inputLabel font-24'>Notes</label> */}
          <Row align="top" className="mb-2">
            <Col xs={24} md={8}>
              <label className="inputLabel" >Note</label>
            </Col>
            <Col xs={24} md={16}>
              <AppInput
              
                placeholder="Add note"
                value={description}
                name={"description"}
                onChange={handleChange}
                style={{ paddingLeft: 13 }}
                multiple
              />
            </Col>
            <div className="text-right w-100">{description?.length}/500</div>
          </Row>
        </>
        {/* </TabPane>
        <TabPane tab='Project plan details' key='2'>
          <CreateTable />
        </TabPane>
        <TabPane tab='Goals' key='3'>
        </TabPane>
      </Tabs> */}

        <Row justify="end">
          {/* <Button
            type='primary'
            style={{ backgroundColor: '#858E99', borderColor: '#858E99' }}
            onClick={() => handleChange('visible', false)}
            className='cancelPlanBtn'
          >
            Cancel
          </Button> */}
          <Button
            type="primary"
            loading={loading}
            onClick={handleCreatePlan}
            disabled={
              !name ||
              moment(start_line).isAfter(moment(dead_line)) ||
              // !purpose ||
              !start_line ||
              !dead_line ||
              description.length > 500
              // ||
              // !invited_owner
            }
            className="createPlanBtn"
          >
            {active === "1" ? "Next step" : "Create"}
          </Button>
        </Row>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => handleChange("inviteModal", false)}
        title={false}
        open={inviteModal}
      >
        <div className="font-18 mb-1">Share this task</div>
        <div>
          Sharing <b className="underline">{name}</b> task
        </div>
        <div className="ownerModalEmail">
          <div className="ownerEmailDiv">{inviteEmail}</div>
        </div>
        <Button
        style={{display:"flex", justifyContent:"center" , alignItems:"center"} }
          icon={<MailOutlined />}
          className="inviteButton"
          type="primary"
          loading={loadingInvite}
          disabled={!inviteEmail}
          onClick={handleInvite}
        >
          Invite
        </Button>
      </Modal>
    </>
  );
}





