import { Popover } from "antd";
import { useProject } from "utils/utility";

import { ReactComponent as AppsIcon } from "assets/svg/appSwitcher.svg";
import { ReactComponent as LogoDM } from "assets/images/auth/logoDM.svg";
import { ReactComponent as LogoPP } from "assets/images/auth/logoPP.svg?v=1";
import { ReactComponent as LogoGPS } from "assets/images/auth/logoGPS.svg";
import { ReactComponent as LogoHC } from "assets/images/auth/logoHC.svg";
import { ReactComponent as LogoAPM } from "assets/images/auth/logoAPM.svg";

import * as Styled from './styled';
import customStyles from './global.module.scss';
import { useAllocatorAccess } from "../../utils/utility";
import { ALLOCATOR_TOOL } from "../../utils/routes";

const getPath = () => {
  const location = window.location.href;

  // Array of staging urls
  const validURLs = [
    'project-planner-36424-staging.botics.co',
    'localhost',
    '127.0.0.1'
  ];

  if (process.env.NODE_ENV === 'development' || validURLs.some(url => location.includes(url))) {
    return {
      hcURL: 'hat-arootah-web-24408-staging.botics.co',
      gpsURL: 'goal-setting-workbo-37-staging.botics.co'
    }
  }
  else if (location.includes('dev.apps.arootah.com')) {
    return {
      hcURL: 'dev.app.arootah.com',
      gpsURL: 'dev.goals.arootah.com'
    }
  }

  return {
    hcURL: 'app.arootah.com',
    gpsURL: 'goals.arootah.com',
  }
}

const AppSwitcher = ({ app }) => {
  const { project } = useProject();
  const isAllocatorUser = useAllocatorAccess();

  const activeProject = project || app || null;

  const { hcURL, gpsURL } = getPath();

  return (
    <Popover
      overlayClassName={customStyles.popover}
      placement="bottomLeft"
      padding={0}
      content={
        <Styled.Box>
          <Styled.AppsList>
            <Styled.AppLink href={`https://${gpsURL}`}>
              <LogoGPS />
              <Styled.AppItemTitle>Goal Manager</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppLink href={`https://${gpsURL}/time-manager`}>
              <LogoAPM />
              <Styled.AppItemTitle>Time Manager</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppLink href={`https://${hcURL}`}>
              <LogoHC />
              <Styled.AppItemTitle>Habit Manager</Styled.AppItemTitle>
            </Styled.AppLink>

            <Styled.AppItem to={isAllocatorUser ? `/${ALLOCATOR_TOOL}` : "/decision-manager"} $active={activeProject === 'decision-manager'}>
              <LogoDM />
              <Styled.AppItemTitle>{isAllocatorUser ? "Allocator Tool" : "Decision Coach"}</Styled.AppItemTitle>
            </Styled.AppItem>

            <Styled.AppItem to="/project-planner" $active={activeProject === 'project-planner'}>
              <LogoPP />
              <Styled.AppItemTitle>Project Manager</Styled.AppItemTitle>
            </Styled.AppItem>
          </Styled.AppsList>

          <Styled.Footer>
            <Styled.Link href="https://arootah.com" target="_blank">www.arootah.com</Styled.Link>
          </Styled.Footer>
        </Styled.Box>
      }
      trigger="click"
    >
      <Styled.Toggle $project={activeProject}>
        <AppsIcon />
      </Styled.Toggle>
    </Popover>
  );
}

export default AppSwitcher;
