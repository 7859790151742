import debounce from "lodash.debounce";
import { useState, useEffect, useCallback } from "react";
import { NumericFormat } from "react-number-format";
import { useSelector } from "react-redux";
// import { Range, getTrackBackground } from 'react-range';

import styles from "./styles.module.scss";
import cc from "classnames";

import GoalDropdown from "./GoalDropdown";
import { getCustomUnitName } from "./utils";

const GoalSettings = ({
  isExpand,
  isShared,
  handleChange,
  handleUpdateGoal,
}) => {
  const { planDetails } = useSelector((state) => state.plan.plan);

  const [start, setStartCopy] = useState("");
  const [current, setCurrentCopy] = useState("");
  const [target, setTargetCopy] = useState("");
  const [placeholderStart, setPlaceholderStart] = useState('0.00');
  const [placeholderCurrent, setPlaceholderCurrent] = useState('0.00');
  const [placeholderTarget, setPlaceholderTarget] = useState('0.00');

  // const [isEdit, setIsEdit] = useState(false);

  const debouncedHandleUpdateGoal = useCallback(
    debounce((field, value) => {
      handleUpdateGoal(field, value);
    }, 500),
    []
  );

  useEffect(() => {
    setStartCopy(planDetails?.goal?.start?.toString() ?? "");
    setCurrentCopy(planDetails?.goal?.current?.toString() ?? "");
    setTargetCopy(planDetails?.goal?.target?.toString() ?? "");
  }, [
    planDetails?.goal?.start,
    planDetails?.goal?.current,
    planDetails?.goal?.target,
  ]);

  const onChangeHandle = (field, value) => {
    if (field === "start") setStartCopy(value);
    else if (field === "current") setCurrentCopy(value);
    else if (field === "target") setTargetCopy(value);

    if (value && !isNaN(value)) {
      debouncedHandleUpdateGoal(field, value);
    } else {
      debouncedHandleUpdateGoal(field, null)
    }
  };

  const disabledGoalInput = isShared || planDetails?.goal?.label === null;

  /* return (
    <input
      type="number"
      name="start"
      value={start}
      onChange={evt => onChangeHandle('start', evt.target.value)}
      step="1"
    />
  ) */

  /* const startNumb = parseFloat(start),
      currentNumb = parseFloat(current),
      targetNumb = parseFloat(target); */

  // const isValid = startNumb < targetNumb && currentNumb >= startNumb && currentNumb <= targetNumb;

  /* useEffect(() => {
    if (!isValid && start && current && target) {
      setIsEdit(true);
    }
  }, [isValid, start, current, target]); */

  /* const onEditClickHandle = () => {
    setIsEdit(prevIsEdit => isEdit ? !isValid : !prevIsEdit);

    if (isValid) {
      handleUpdateGoal('start', start);
      handleUpdateGoal('current', current);
      handleUpdateGoal('target', target);
    }
  } */

  /* const onRangeChangeHandle = values => {
    setCurrentCopy(values[0]);
    debouncedHandleUpdateGoal('current', values[0]);
  } */

  return (
    <div className={cc(styles.goal, { [styles.active]: isExpand })}>
      <div className={styles.goalBox}>
        <div className={styles.goalBoxHead}>
          <p>Set Your Goal</p>

          {/* <button className={styles.actionBtn} type="button" disabled={!isValid} style={{ cursor: isValid ? 'pointer' : 'not-allowed' }} onClick={onEditClickHandle}>
            {isEditMode
              ? 'Save'
              : (
                <>
                  <EditIcon />
                  Edit
                </>
              )}
          </button> */}
        </div>

        <div className={styles.goalBoxBody}>
          <div className={styles.card}>
            <p className={styles.title}>Unit</p>
            <div className={styles.unitText}>
              <GoalDropdown
                planDetails={planDetails}
                disabled={isShared}
                handleChange={handleChange}
                handleUpdateGoal={handleUpdateGoal}
              />
            </div>
          </div>

          <div className={styles.goalBoxContent}>
            <div className={styles.inputGroup}>
              <div className="d-flex align-items-center">
                <span className={styles.goalTitle}>Start</span>
                <div className={styles.unitTitle}>
                  {getCustomUnitName(planDetails)}
                </div>
              </div>

              <NumericFormat
                name="start"
                value={start==='0.00' ? '' : start}
                decimalScale={2}
                fixedDecimalScale
                disabled={disabledGoalInput}
                onChange={(evt) => onChangeHandle("start", evt.target.value)}
                 onFocus={() => setPlaceholderStart('')} 
                onBlur={() => setPlaceholderStart('0.00')} 
                placeholder={placeholderStart}
              />
            </div>

            <div className={styles.inputGroup}>
              <div className="d-flex align-items-center">
                <span className={styles.goalTitle}>Current</span>
                <div className={styles.unitTitle}>
                  {getCustomUnitName(planDetails)}
                </div>
              </div>

              <NumericFormat
                name="current"
                value={current==='0.00' ? '' : current}
                decimalScale={2}
                fixedDecimalScale
                disabled={disabledGoalInput}
                onChange={(evt) => onChangeHandle("current", evt.target.value)}
                onFocus={() => setPlaceholderCurrent('')} 
                onBlur={() => setPlaceholderCurrent('0.00')} 
                placeholder={placeholderCurrent}
              />
            </div>

            <div className={styles.inputGroup}>
              <div className="d-flex align-items-center">
                <span className={styles.goalTitle}>Target</span>
                <div className={styles.unitTitle}>
                  {getCustomUnitName(planDetails)}
                </div>
              </div>

              <NumericFormat
                name="target"
                value={target==='0.00' ? '' : target}
                decimalScale={2}
                fixedDecimalScale
                allowemptyformatting ="true"
                disabled={disabledGoalInput}
                onChange={(evt) => onChangeHandle("target", evt.target.value)}
                onFocus={() => setPlaceholderTarget('')} 
                onBlur={() => setPlaceholderTarget('0.00')} 
                placeholder={placeholderTarget}
              />
            </div>
          </div>

          {/* {!isEditMode && <div className={styles.goalRangeContent}>
            <div className={styles.rangeSteps}>
              <span>Start</span>
              <span>Current</span>
              <span>Target</span>
            </div>

            <Range
              step={0.01}
              min={startNumb}
              max={targetNumb}
              values={[currentNumb]}
              onChange={onRangeChangeHandle}
              renderTrack={({ props, children }) => (
                <div
                  ref={props.ref}
                  className={styles.rangePath}
                  {...props}
                  style={{
                    ...props.style,
                    background: getTrackBackground({
                      values: [currentNumb],
                      colors: ["#FF9900", "#F5F5F5"],
                      min: startNumb,
                      max: targetNumb
                    })
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <div
                  className={styles.rangeHead}
                  {...props}
                />
              )}
            />

            <ul className={styles.rangeValues}>
              <li title={`${start} ${unitLabel}`}>{start} <span>{unitLabel}</span></li>
              <li title={`${start} ${unitLabel}`}>{current} <span>{unitLabel}</span></li>
              <li title={`${start} ${unitLabel}`}>{target} <span>{unitLabel}</span></li>
            </ul>
          </div>} */}
        </div>
      </div>
    </div>
  );
};

export default GoalSettings;
