import { FC, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { arrayMoveImmutable } from "array-move";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import moment, { Moment } from "moment";
import { DatePicker } from "antd";

import {
  Wrapper,
  Head,
  Col,
  WeightContent,
  NameContent,
  NameWrapper,
  AddActionItemBtn,
  Toggle,
  CollapseWrapper,
  Body,
  ActionButton,
} from './styled';

import { DraggableItem, NewItem } from '../Item';
import InputWrapper from "../InputWrapper";
import { DeleteItemModal } from "../DeleteItemModal";

import EditIcon from "../../../../assets/svg/edit.svg";
import TrashIcon from "../../../../assets/svg/trash.svg";

import upIcon from './up.svg';
import addIcon from './add.svg';
import pencilIcon from './pencil.svg';

// @ts-ignore
import { changeActionItemsOrder, createActionItem, updatePlanCategory } from "modules/actions/PlanActions";

import { ICategory, IItem } from "../@types";

// @ts-ignore
import classes from './styled.module.scss';

// @ts-ignore
import { getTime /* , getTimeText, validateEmail */ } from "utils/utility";
import { ValidateCategoryStartLineValue, ValidateCatrgoryDeadLineValue } from "../../../../utils/validateValue";
import ErrorTooltip from "../../../../components/ErrorTooltip";

// import ValidateValue from "../../../../utils";

interface IProps {
  data?: ICategory;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
  }
  isActive?: boolean;
  isNew?: boolean;
  isShort: boolean;
  isShared: boolean;
  onToggle?: (id: string) => void;
  onCreate?: (value: string) => void;
  onAdjustWeight?: () => void;
  isExpandAllNotes?: boolean;
  provided?: any;
}

interface IAddProps {
  isShared: boolean;
  isShort: boolean;
  onCreate: (value: string) => void;
}

interface IHandle {
  focusInput: () => void;
}

export const AddCategory: FC<IAddProps> = ({ isShared, isShort, onCreate }) => {
  const $categoryRef = useRef<IHandle>(null);

  useEffect(() => {
    $categoryRef.current?.focusInput();
  }, []);

  return (
    <Category isNew isShared={isShared} isShort={isShort} onCreate={onCreate} ref={$categoryRef} />
  );
}

const Category = forwardRef<IHandle, IProps>(({ data, additionalData, isActive, isNew, isShared, isShort, onToggle, onCreate, onAdjustWeight, isExpandAllNotes, provided }, ref) => {
  const { id, name, duration, weight, plan_progress, category_progress, project_plan, start_line, dead_line, start, current, target, action_items } = data || ({} as ICategory);

  const dispatch = useDispatch();

  const [isEditItemText, setIsEditItemText] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const $nameRef = useRef<IHandle>(null);
  const $newItemRef = useRef<IHandle>(null);

  useImperativeHandle(ref, () => ({
    focusInput: () => {
      if ($nameRef.current) {
        $nameRef.current.focusInput();
      }
    }
  }));

  const onClickHandle = () => {
    if (onToggle)
      onToggle(id.toString());
  };

  const onDragEndHandle = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const oldIndex = result.source.index;
    const newIndex = result.destination.index;

    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        action_items.slice(),
        oldIndex,
        newIndex
      );

      const actions: Pick<IItem, 'id' | 'order_id'>[] = [];

      newData?.forEach((element, index) => {
        if (element) {
          actions?.push({
            id: element?.id,
            order_id: index,
          });
        }
      });

      const payload = {
        category: id,
        actions,
      };

      dispatch(changeActionItemsOrder(payload, project_plan));
    }
  };

  /* const getCategoryByID = (id: number) => {
    return planDetails?.categories?.find((e) => e?.id === c_id);
  }; */

  const handleCreateActionItem = (value: string) => {
    const payload = {
      name: value,
      start_line: moment(start_line ?? new Date()).format("YYYY-MM-DD"),
      dead_line: moment(dead_line ?? new Date()).format("YYYY-MM-DD"),
      project_plan: project_plan,
      category: id,
    };

    dispatch(createActionItem(payload));
    // toggleExpandedKeys(record?.key);
  };

  const handleNameChange = (value: string, name: string) => {
    if (isNew) {
      onCreate?.(value);
      return;
    }

    if (!value)
      return;

    dispatch(updatePlanCategory(id, { project_plan, [name]: value }, true));
  }

  const handleAddActionItem = () => {
    if ($newItemRef && $newItemRef.current) {
      if (onToggle && !isActive) {
        onToggle(id.toString());

        setTimeout(() => {
          $newItemRef.current?.focusInput();
        }, 500);
      }
      else {
        $newItemRef.current?.focusInput();
      }
    }
  }


  const isDateDisabled = (current: Moment, name: "start_line" | "dead_line"): boolean => {
    if (!current) return false;

    if (name === "start_line") {
      if (additionalData?.plan_start_date && current.isBefore(moment(additionalData.plan_start_date), 'day')) {
        return true;
      }
      if (additionalData?.plan_end_date && current.isAfter(moment(additionalData.plan_end_date), 'day')) {
        return true;
      }
    } else if (name === "dead_line") {
      if (additionalData?.plan_start_date && current.isBefore(moment(additionalData.plan_start_date), 'day')) {
        return true;
      }
      if (additionalData?.plan_end_date && current.isAfter(moment(additionalData.plan_end_date), 'day')) {
        return true;
      }
      if (start_line && current.isBefore(moment(start_line), 'day')) {
        return true;
      }
    }

    return false;
  };

  const startPlanDate = additionalData?.plan_start_date || null
  const endPlanDate = additionalData?.plan_end_date || null

  const startLineError = ValidateCategoryStartLineValue(start_line, startPlanDate, endPlanDate);
  const deadLineError = ValidateCatrgoryDeadLineValue(dead_line, startPlanDate, endPlanDate, start_line)

  return (
    <Wrapper $color="#FF9900" isShort={isShort} ref={provided?.innerRef} {...provided?.droppableProps}>
      <Head>
        <Col>
          <Toggle $active={isActive} onClick={onClickHandle} title="Collapse category" data-testid="expand-collapse-button">
            <img src={upIcon} alt="" />
          </Toggle>
        </Col>
        <Col>
          <NameContent $disabled={isShared}>

            <NameWrapper>
              <InputWrapper
                name="name"
                defaultValue={name || ""}
                placeholder=" - "
                disabled={isShared}
                onChange={handleNameChange}
                ref={$nameRef}
                data-testid="category-input"
                textCenter={false}
                maxWordLength={255}
                setIsEditItemText={setIsEditItemText}
                isEditItemText={isEditItemText}
                notEditOnText={true}
              />
            </NameWrapper>

            {isNew ? null : (
              <ActionButton className="items-actions">
                <AddActionItemBtn onClick={handleAddActionItem} disabled={isShared} data-testid="add-item-button">
                  <img src={addIcon} alt="" />
                  Add Action Item
                </AddActionItemBtn>

                {
                  isShared ? null :
                    <>
                      <img src={EditIcon} alt="" style={{ cursor: 'pointer' }}
                        onClick={() => setIsEditItemText(true)}
                      />

                      <img src={TrashIcon} alt="" style={{ cursor: 'pointer' }}
                        onClick={() => setIsDeleteModalVisible(true)}
                      />
                    </>

                }


                <DeleteItemModal
                  itemId={id}
                  itemName={name ?? ""}
                  planId={project_plan}
                  isVisible={isDeleteModalVisible}
                  type="category"
                  onClose={() => {
                    setIsDeleteModalVisible(false)
                  }}
                />

              </ActionButton>
            )}
          </NameContent>
        </Col>

        <Col $center data-testid="duration-category-value">{duration ? getTime(duration) : ''}</Col>

        <Col $center>
          {isNew ? null : <WeightContent onClick={onAdjustWeight} disabled={isShared} title="Adjust weight..." data-testid="weight-category-button">
            {(Number(weight) * 100).toFixed(2)}%
            {isShared ? null : <img src={pencilIcon} alt="" />}
          </WeightContent>}
        </Col>

        <Col $center data-testid="plan-progress-value">{isNew ? null : `${(Number(plan_progress) * 100).toFixed(2)}%`}</Col>
        {isShort ? null : <Col $center data-testid="result-progress-value">{isNew ? null : `${(Number(category_progress) * 100).toFixed(2)}%`}</Col>}
        <Col></Col>

        <Col $center>
          <div className="d-flex align-items-center justify-content-center gap-1">
            {isNew ? null : (isShared ? (start_line ? moment(start_line).format("YYYY-MM-DD") : null) : <DatePicker
              className={classes.datepicker}
              format={"MM/DD/YYYY"}
              disabledDate={(current) => isDateDisabled(current, "start_line")}
              value={start_line ? moment(start_line) : null}
              disabled={isShared}
              onChange={(_: any, value: string) => dispatch(updatePlanCategory(id, { "start_line": value ? moment(value).format("YYYY-MM-DD") : null }, true))}
              data-testid="start-category-date"
            />)}
            {
              startLineError &&
              <ErrorTooltip title={startLineError || ''} />
            }
          </div>
        </Col>


        <Col $center>
          <div className="d-flex align-items-center justify-content-center gap-1">
            {isNew ? null : (isShared ? (dead_line ? moment(dead_line).format("YYYY-MM-DD") : null) : <DatePicker
              className={classes.datepicker}
              format={"MM/DD/YYYY"}
              disabledDate={(current) => isDateDisabled(current, "dead_line")}
              value={dead_line ? moment(dead_line) : null}
              disabled={isShared}
              onChange={(_: any, value: string) => dispatch(updatePlanCategory(id, { "dead_line": value ? moment(value).format("YYYY-MM-DD") : null }, true))}
              data-testid="end-category-date"
            />)}
            {
              deadLineError &&
              <ErrorTooltip title={deadLineError || ''} />
            }
          </div>
        </Col>

        {isShort ? null : <Col $center>
          {isNew ? null : <InputWrapper
            name="start"
            type="number"
            defaultValue={start || ""}
            placeholder=" - "
            disabled={isShared}
            // error={startError}
            onChange={(value, name) => {
              dispatch(updatePlanCategory(id, { project_plan, [name]: value ? value /* Number(value).toFixed(2) */ : null }, true))
            }
            }

            data-testid="start-category-input"
          />}
        </Col>}

        {isShort ? null : <Col $center>
          {isNew ? null : <InputWrapper
            name="current"
            type="number"
            defaultValue={current || ""}
            placeholder=" - "
            disabled={isShared}
            onChange={(value, name) => {
              dispatch(updatePlanCategory(id, { project_plan, [name]: value ? value /* Number(value).toFixed(2) */ : null }, true))
            }}
            data-testid="current-category-input"
          />}
        </Col>}

        {isShort ? null : <Col $center>
          {isNew ? null : <InputWrapper
            name="target"
            type="number"
            defaultValue={target || ""}
            placeholder=" - "
            disabled={isShared}
            onChange={(value, name) => {
              dispatch(updatePlanCategory(id, { project_plan, [name]: value ? value /* Number(value).toFixed(2) */ : null }, true))
            }}
            data-testid="target-category-input"
          />}
        </Col>}
      </Head>

      {isNew ? null : <Accordion.Collapse as={CollapseWrapper} eventKey={id.toString()}>
        <>

          <Body>
            {action_items.map((item, i) => (
              <DraggableItem
                data={item}
                additionalData={{
                  plan_start_date: additionalData?.plan_start_date || null,
                  plan_end_date: additionalData?.plan_end_date || null,
                  category_start_date: start_line,
                  category_end_date: dead_line,
                }}
                isExpand={isExpandAllNotes}
                projectPlanId={project_plan}
                isShared={isShared}
                isShort={isShort}
                index={i}
                key={item.id} />
            ))}
            {provided.placeholder}
          </Body>

          {isShared ? null : <NewItem
            projectPlanId={project_plan}
            isShort={isShort}
            ref={$newItemRef}
            onCreate={handleCreateActionItem}
          />}
        </>
      </Accordion.Collapse>}
    </Wrapper>
  );
});

export default Category;