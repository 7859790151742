import { toast } from "react-toastify";

export const handleValidation = (
  value: number,
  name: string,
  start: number | null,
  current: number | null,
  target: number | null
) => {
  const val = Number(value);

  if (name === "start" && current !== null && val > current) {
    toast.error("Start value must be less than current value");
    return false;
  } else if (name === "start" && target !== null && val > target) {
    toast.error("Start value must be less than target value");
    return false;
  } else if (name === "current" && start !== null && val < start) {
    toast.error("Value must be greater than start value");
    return false;
  } else if (name === "current" && target !== null && val > target) {
    toast.error("Value must be less than target value");
    return false;
  } else if (name === "target" && current !== null && val < current) {
    toast.error("Target value must be greater than current value");
    return false;
  } else if (name === "target" && start !== null && val < start) {
    toast.error("Target value must be greater than start value");
    return false;
  }
  return true;
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    day: "numeric",
  };
  return date.toLocaleDateString("en-US", options);
};
