import React, { useEffect, useState } from "react";
import classes from "./AIAssistantModal.module.scss";
import { ReactComponent as AssistantIcon } from "./assistant.svg";
import { ReactComponent as CloseIcon } from "./close.svg";
import FormInput from "components/FormInput/FormInput";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "antd";
import {
  GenerateAIDefinition,
  SetAIDefinition,
} from "redux/DecisionMatrix/GenerateAIDefinition/action";
import Skeleton from "scenes/DecisionMatrix/components/Skeleton/skeleton";
import { websocketConnection } from "utils/websocket";

const AIDefinitionModal = ({
  onClose,
  onSelectedCriteriaChange,
  selectedCriteria,
  decisionID,
  criteria,
  handleApply,
}) => {
  const dispatch = useDispatch();
  const [definition, setDefinition] = useState("");
  // const { loading, success, error } = useSelector(
  //   (state) => state.generateAIDefinitionReducer
  // );
  const [isDefinitionSuggestionLoading, setIsDefinitionSuggestionLoading] =
    useState(false);
  const [response, setResponse] = useState("");
  const [errors, setErrors] = useState();

  useEffect(() => {
    // websocketConnection?.connect();
    websocketConnection?.setMessageCallback(handleMessage);

    return () => {
      // websocketConnection.close();
      setResponse("");
      setErrors("");
    };
  }, []);
  // useEffect(() => {
  //   // if (isPurposeLoading || isObjectiveLoading) {
  //   let timer;
  //   // if (websocketConnection) {
  //   timer = setInterval(() => {
  //     websocketConnection.send(
  //       JSON.stringify({
  //         pong: "pong",
  //       })
  //     );
  //   }, 1000);
  //   // }
  //   return () => {
  //     clearInterval(timer);
  //   };
  //   // }
  // }, []);
  const handleMessage = (message) => {
    // Handle the received message
    let res = JSON.parse(message);
    if (res.ping || res.pong) return;
    setResponse(res?.message); // Set the received message as the response
    setErrors(res?.message?.error?.message);
    setIsDefinitionSuggestionLoading(false);
  };

  useEffect(() => {
    if (response) {
      setDefinition(response?.generated_description);
    }
  }, [response]);

  useEffect(() => {
    return () => {
      dispatch(SetAIDefinition(null));
      setDefinition("");
    };
  }, []);
  const handleSuggest = () => {
    const criteriaSelected = criteria?.find(
      (c) => c?.id === selectedCriteria?.id
    );

    if (criteriaSelected) {
      setErrors("");
      setIsDefinitionSuggestionLoading(true);
      websocketConnection?.send(
        JSON.stringify({
          endpoint: "generate_criteria_description",
          model_id: decisionID,
          title: selectedCriteria?.title,
        })
      );
      // dispatch(
      //   GenerateAIDefinition(
      //     `?decision=${decisionID}&title=${criteriaSelected.title}`
      //   )
      // );
    }
  };

  const getOptions = () => {
    const list = [{ value: "", label: "Select Criteria" }];
    criteria?.map((item) => {
      if (item?.title?.length > 0){
        list?.push({ value: item?.id, label: item?.title });
      }
    });
    return list;
  };
  return (
    <>
      <div className={classes.background} />
      <div className={classes.modal}>
        <div className={classes.wrapper}>
          <div className={classes.header}>
            <div className={classes.heading}>
              <AssistantIcon />
              <span>AI Assistant</span>
              <span className={classes.beta}>Beta</span>
            </div>
            <button
              type="button"
              onClick={onClose}
              className={classes.close}
              title="Close"
            >
              <CloseIcon />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>
          <div className={classes.body}>
            <p>Definition Suggestion</p>
            <p className={classes.suggestText}>
              Generate definition for your each criteria
            </p>
            <Row gutter={8}>
              <Col span={19}>
                <div className={`${classes.inputField}`}>
                  <FormInput
                    height="44px"
                    name={"selectedCriteria"}
                    error={errors ? errors : null}
                    setFieldValue={onSelectedCriteriaChange}
                    value={selectedCriteria?.id}
                    type="select"
                    options={getOptions()}
                  />
                </div>
              </Col>
              <Col span={5}>
                <button
                  type="button"
                  disabled={isDefinitionSuggestionLoading}
                  className={classes.suggest}
                  onClick={handleSuggest}
                >
                  {isDefinitionSuggestionLoading ? "Suggesting" : "Suggest"}
                </button>
              </Col>
            </Row>
            {isDefinitionSuggestionLoading ? (
              <Skeleton />
            ) : (
              <div className={`${classes.inputField}`}>
                <FormInput
                  style={{ background: "#F4F4F4" }}
                  placeholder={"Enter your description"}
                  type="textarea"
                  className="mt-4"
                  name={"definition"}
                  setFieldValue={(name, value) => setDefinition(value)}
                  value={definition}
                />
              </div>
            )}
          </div>

          <div className={classes.footer}>
            <button
              type="button"
              className={classes.tryagain}
              onClick={handleSuggest}
            >
              Try Again
            </button>
            <button
              style={{ opacity: !response ? 0.5 : 1 }}
              type="button"
              disabled={!response}
              onClick={() => handleApply(selectedCriteria, definition)}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export { AIDefinitionModal };
