import React, { useCallback, useEffect, useRef, useState } from "react";

// moment
import moment from "moment";

// styles
import styles from "./style.module.scss";

// antd
import { Select, Button, Modal, Row, Col, Collapse, Dropdown } from "antd";

// Assets
import { ReactComponent as SearchIcon } from "assets/svg/Search.svg";
import { ReactComponent as Cross } from "assets/svg/close.svg";
import { ReactComponent as FilterIcon } from "assets/svg/filter.svg";
import { ReactComponent as PPLogo } from "assets/svg/logoPP_1_3.svg";
import { ReactComponent as SortIcon } from "assets/svg/sort.svg";
import { ReactComponent as NoPlan } from "assets/svg/NoPlan.svg";
import { ReactComponent as CardViewIcon } from "assets/svg/cardView.svg";
import { ReactComponent as ListViewIcon } from "assets/svg/listview.svg";
import { DownOutlined, RightOutlined } from "@ant-design/icons";

// Icons
import { PlusOutlined } from "@ant-design/icons";

// Redux
import { useDispatch, useSelector } from "react-redux";

// Modules
import { createPlan, getPlans, updatePlan } from "modules/actions/PlanActions";
import { getProjectOwners, inviteOwner } from "modules/actions/UserActions";

// Components
import SortByGroup from "../ProjectPlanner/SortByGroup";
import SortBy from "../ProjectPlanner/SortBy";
import { AppLoading } from "components/AppLoading";
import { Card } from "../ProjectPlanner/DragnDrop";
import PlanCard from "../ProjectPlanner/PlanCard";
import ProjectTable from "../ProjectPlanner/ProjectTable";
import SortByAreaTableView from "../ProjectPlanner/SortByArea/TableView";
import CreatePlanModal from "../ProjectPlanner/CreatePlanModal";
import PlanList from "../ProjectPlanner/PlanList";

// constants
import { statuses } from "constants/index";

// Utils
import { sortBy } from "../../utils/sortBy";
import update from "immutability-helper";

const { Panel } = Collapse;

const PAGINATION_LIMIT = 25;

const NewProjectPlanner = () => {
  const dispatch = useDispatch();
  const filterRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [dashboardModel, setDashboardModel] = useState(false);
  const [isListView, setIsListView] = useState(false);

  const { loading, allPlans, loadingUpdatePlan } = useSelector(
    (state) => state.plan.plan
  );

  const { user } = useSelector((state) => state.auth);
  const { users } = useSelector((state) => state.user.projectOwners);
  const { loading: inviteLoading } = useSelector(
    (state) => state.user.invitedUser
  );

  const userData = user?.data;

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [search, setSearch] = useState("");

  const [state, setState] = useState({
    activeKey: [],
    allAreas: [],
    selectedSortBy: "",
    selectedLocations: [],
    visible: false,
    isTableView: false,
    name: "",
    start_line: "",
    dead_line: "",
    description: "",
    purpose: "",
    ownerText: "",
    inviteEmail: "",
    invited_owner: user?.data?.email,
    active: true,
    inviteModal: false,
    isHierarchy: false,
    isAscending: true,
    openFilter: false,
    openSort: false,
    isArchive: false,
    l: PAGINATION_LIMIT,
    o: 0,
  });

  const {
    activeKey,
    allAreas,
    selectedSortBy,
    isAscending,
    selectedLocations,
    visible,
    isTableView,
    name,
    start_line,
    dead_line,
    description,
    active,
    purpose,
    invited_owner,
    isHierarchy,
    ownerText,
    inviteModal,
    inviteEmail,
    isArchive,
    openSort,
    l,
    o,
  } = state;

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };
  const handleArchive = () => {
    setSelectedStatus([]);
    if (isArchive) {
      handleChange("isArchive", false);
      handleGetPlan(false);
    } else {
      handleChange("isArchive", true);
      handleGetPlan(true);
    }
  };

  const handleCollapsedSingle = (selected) => {
    if (activeKey.some((e) => e === selected)) {
      const removed = activeKey.filter((e) => e !== selected);
      handleChange("activeKey", removed);
    } else {
      const added = [...activeKey, selected];
      handleChange("activeKey", added);
    }
  };

  const getOwnerIDByEmail = () => {
    const filtered = users?.filter((e) => e.email === invited_owner);
    return filtered?.length > 0 ? filtered[0]?.id : null;
  };

  const handleCreatePlan = (e) => {
    const payload = {
      name,
      start_line: moment(start_line).format("YYYY-MM-DD"),
      dead_line: moment(dead_line).format("YYYY-MM-DD"),
      description,
      created_at: new Date(),
      updated_at: new Date(),
      invited_owner,
      owner: getOwnerIDByEmail(),
      areas: [1],
    };

    dispatch(createPlan(payload, handlePlanModal, resetState));
  };

  const handleClosePlanModal = (e) => {
    handleChange("name", "");
    handleChange("start_line", "");
    handleChange("dead_line", "");
    handleChange("description", "");
    handleChange("invited_owner", user?.data?.email);
    handleChange("visible", false);
  };

  const debounceDelay = 500; // Adjust this value to control the delay.

  useEffect(() => {
    let spread = false;

    if (!search) return handleGetPlan(false, spread);

    const timer = setTimeout(() => {
      handleGetPlan(false, spread);
    }, debounceDelay);

    return () => {
      clearTimeout(timer);
    };
  }, [search]);

  useEffect(() => {
    let spread = true;
    handleGetPlan(false, spread);
  }, [l, o]);

  const handleGetPlan = (isArchive, spread) => {
    setIsLoading(true);
    const basePayload = isArchive ? "?is_archived=true" : "?is_archived=false";
    const payload = `${basePayload}&search=${search}${`&l=${l}&o=${o}`}`;

    dispatch(getPlans(payload, spread, search));
    dispatch(getProjectOwners(""));
    setIsLoading(false);
  };

  const resetState = () => {
    handleChange("name", "");
    handleChange("start_line", "");
    handleChange("dead_line", "");
    handleChange("description", "");
    handleChange("purpose", "");
    handleChange("invited_owner", user?.data?.email);
  };

  const handleInvite = (e) => {
    const payload = { invited_owner: inviteEmail };
    dispatch(inviteOwner(payload, handleOwnerModal));
  };

  const handlePlanModal = (value, isNew) => {
    handleChange("visible", value);
    if (isNew) {
      const filtered = users?.filter((e) => e.email === invited_owner);
      handleChange(
        "invited_owner",
        filtered?.length > 0 ? filtered[0]?.username : ""
      );
    }
  };

  const handleOwnerModal = (value) => {
    handleChange("inviteModal", value);
  };

  const handleUpdatePlan = (id, payload, closeModal) => {
    dispatch(updatePlan(id, payload, closeModal, true));
  };

  const getSTatusName = (status) => {
    const res = statuses?.filter(
      (e) => e?.value?.toLowerCase() === status?.toLowerCase()
    );
    return res[0]?.key;
  };

  const handleSearch = (evt) => {
    setIsLoading(true);
    setSearch(evt.target.value);
  };

  const filteredPlans = allPlans;

  const sortedPlans = sortBy(filteredPlans, selectedSortBy, isAscending);

  const getPlansLengthNyStatus = (status) => {
    return sortedPlans?.filter(
      (e) =>
        e?.status?.toLowerCase() === status?.toLowerCase() &&
        (isArchive ? e.is_archived : !e.is_archived)
    );
  };
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    setSelectedStatus((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const renderCard = useCallback(
    (card, index) => (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        children={card.children}
        moveCard={moveCard}
      />
    ),
    [moveCard]
  );

  const handleCloseFilter = useCallback((value) => {
    filterRef.current?.focus();
  }, []);

  const archiveData = sortedPlans?.filter((item) => item.is_archived);

  const closeAll = () => {
    setDashboardModel(false);
  };

  const View = isListView ? PlanList : PlanCard;

  return (
    <>
      <Modal
        open={dashboardModel}
        title={false}
        footer={false}
        closable={false}
        width={1200}
        onCancel={closeAll}
        centered
        className="dashboardModal"
      >
        <Row justify="space-between">
          <PPLogo width={190} />
          <Cross onClick={closeAll} className={"c-pointer"} />
        </Row>

        <body className={styles.dashboardModelBody}>
          <h1>Welcome to Project Manager, {userData && userData.name}</h1>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim: veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur.
          </p>

          <button
            onClick={() => {
              handlePlanModal(true);
              closeAll();
            }}
          >
            Start a New project
          </button>
        </body>
      </Modal>

      <div className={styles.NewHeader}>
        <div className={styles.NewHeader_top}>
          <h2 className={styles.title}>Dashboard</h2>

          {/* <div className={styles.NewHeader_top_actions}>
            <div className={styles.action}>
              <DownloadIcon />
              EXPORT
            </div>

            <div className={styles.action}>
              <ShareGray />
              SHARE
            </div>
          </div> */}
        </div>

        <div className={styles.NewHeader_bottom}>
          <Row className={styles.NewHeader_bottom_left}>
            <Button onClick={() => handlePlanModal(true)}>
              <PlusOutlined />
              NEW PROJECT
            </Button>
            {/* <Button>
              <PlusOutlined />
              NEW TASK
            </Button> */}
          </Row>

          <Row className={styles.NewHeader_bottom_right}>
            {/* <Row className={styles.button}>
              <MyTask />
              My Tasks
            </Row> */}

            <Row className={styles.button}>
              {/* {isListView ? <ListViewIcon /> : <CardViewIcon />} */}
              {/* // onClick={() => setIsListView(!isListView)} */}

              <Dropdown
                overlayClassName="listAndCardView_dropdown"
                menu={{
                  items: [
                    {
                      label: "List View",
                      icon: <ListViewIcon />,
                      onClick: () => setIsListView(true),
                    },
                    {
                      label: "Card View",
                      icon: <CardViewIcon />,
                      onClick: () => setIsListView(false),
                    },
                  ],
                }}
                trigger={["click"]}
                showArrow={true}
              >
                <span className="cursor-pointer row-center">
                  {isListView ? <ListViewIcon /> : <CardViewIcon />}{" "}
                  {isListView ? "List View" : "Card View"}
                  {"  "}
                  <DownOutlined />
                </span>
              </Dropdown>
            </Row>

            <Row className="sortByGrop" align="middle">
              <SortIcon className="sortByGrop-icon" />

              <Select
                ref={filterRef}
                className="unique-placeholder"
                placeholder="Sort"
                onClick={() => handleChange("openSort", !openSort)}
                dropdownMatchSelectWidth={false}
                onMouseDown={(e) => {
                  e.preventDefault();
                  return false;
                }}
                dropdownRender={(menu) => (
                  <SortBy
                    selectedSortBy={selectedSortBy}
                    isAscending={isAscending}
                    sorting
                    // sortBy={sortBy}
                    handleChange={handleChange}
                  />
                )}
              ></Select>
            </Row>

            <Row className="sortByGrop" align="middle">
              <FilterIcon className="sortByGrop-icon" />

              <Select
                className="unique-placeholder"
                placeholder="Filter"
                dropdownMatchSelectWidth={false}
                showArrow={false}
                dropdownRender={(menu) => (
                  <SortByGroup
                    handleChange={handleChange}
                    setSelectedStatus={setSelectedStatus}
                    onSave={handleCloseFilter}
                    handleArchive={handleArchive}
                    handleGetPlan={handleGetPlan}
                    isArchive={isArchive}
                    sorting
                    selectedStatus={selectedStatus}
                    selectedLocations={selectedLocations}
                  />
                )}
              />
            </Row>

            <Row className={styles.searchWrapper}>
              <SearchIcon className={styles.icon} width={20} />

              <input
                type="text"
                className={styles.search}
                placeholder={"Search projects"}
                value={search}
                onChange={handleSearch}
              />
            </Row>
          </Row>
        </div>
      </div>

      <Col>
        {isLoading ||
          (loading && (
            <div className={styles.loader}>
              <AppLoading />
            </div>
          ))}

        {(() => {
          const noPlans = !loading && sortedPlans?.length === 0;
          const noPlansWithSearch = sortedPlans?.length === 0 && search;
          const noNonArchivedPlans =
            !isArchive &&
            sortedPlans?.filter((item) => !item.is_archived)?.length === 0;
          const noSelectedStatus = selectedStatus?.length === 0;

          return (
            (noPlans ||
              noPlansWithSearch ||
              (noNonArchivedPlans && noSelectedStatus)) && (
              <NoPlan className={styles.centered} />
            )
          );
        })()}

        {selectedStatus.length > 0 ? (
          <>
            <Row gutter={6}>
              {isTableView && (
                <Col span={6}>
                  <SortByAreaTableView
                    allAreas={allAreas}
                    handleChange={handleChange}
                  />
                </Col>
              )}
              <Col
                span={isTableView ? 18 : 24}
                style={{
                  padding: "0 20px",
                }}
              >
                {selectedStatus.map((selected, index) => {
                  return renderCard(
                    {
                      id: index,
                      children: (
                        <Collapse
                          key={index}
                          defaultActiveKey={
                            activeKey.some((e) => e === selected)
                              ? [`${index + 1}`]
                              : []
                          }
                          activeKey={
                            activeKey.some((e) => e === selected)
                              ? [`${index + 1}`]
                              : null
                          }
                          expandIconPosition="right"
                          className="dashboardCollapse"
                          expandIcon={(props) =>
                            props.isActive ? (
                              <DownOutlined
                                className="expendIcon"
                                onClick={() => {
                                  handleCollapsedSingle(selected);
                                }}
                              />
                            ) : (
                              <RightOutlined
                                className="expendIcon"
                                onClick={() => handleCollapsedSingle(selected)}
                              />
                            )
                          }
                          ghost
                        >
                          <Panel
                            style={{ minHeight: 50, marginTop: 20 }}
                            header={
                              <div
                                className="font-18 text_500 mr-3"
                                onClick={() => handleCollapsedSingle(selected)}
                              >
                                {/* <DragIcon className={"mr-1"} /> */}
                                <span className={styles.status}>
                                  {getSTatusName(selected)}
                                  <span>
                                    ({getPlansLengthNyStatus(selected)?.length})
                                  </span>
                                </span>
                              </div>
                            }
                            key={`${index + 1}`}
                          >
                            <Row gutter={16}>
                              {isTableView ? (
                                <ProjectTable />
                              ) : (
                                <>
                                  {getPlansLengthNyStatus(selected)?.map(
                                    (item, index) => (
                                      <View
                                        index={index}
                                        handleUpdatePlan={handleUpdatePlan}
                                        loadingUpdatePlan={loadingUpdatePlan}
                                        key={index}
                                        isHierarchy={isHierarchy}
                                        item={item}
                                      />
                                    )
                                  )}
                                </>
                              )}
                            </Row>
                          </Panel>
                        </Collapse>
                      ),
                    },
                    index
                  );
                })}
              </Col>
            </Row>
          </>
        ) : (
          <div
            className={styles.wrapper}
            style={{
              flexDirection: isListView ? "column" : "row",
            }}
          >
            {sortedPlans
              ?.filter((item) =>
                isArchive ? item.is_archived : !item.is_archived
              )
              .map((item, index) => (
                <View
                  index={index}
                  status={item?.status}
                  handleGetPlan={handleGetPlan}
                  handleUpdatePlan={handleUpdatePlan}
                  loadingUpdatePlan={loadingUpdatePlan}
                  isHierarchy={isHierarchy}
                  item={item}
                  showAll
                  key={index}
                />
              ))}
            {isArchive && !archiveData?.length && (
              <NoPlan className={styles.centered} />
            )}
          </div>
        )}
      </Col>

      <CreatePlanModal
        name={name}
        inviteModal={inviteModal}
        users={users}
        inviteEmail={inviteEmail}
        ownerText={ownerText}
        start_line={start_line}
        dead_line={dead_line}
        inviteLoading={inviteLoading}
        description={description}
        active={active}
        userEmail={user?.data?.email}
        invited_owner={invited_owner}
        purpose={purpose}
        visible={visible}
        loading={loading}
        onClose={handleClosePlanModal}
        handleCreatePlan={handleCreatePlan}
        handleInvite={handleInvite}
        handleChange={handleChange}
      />
    </>
  );
};

export default NewProjectPlanner;
