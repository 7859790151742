import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 288px);
  overflow-y: auto;
`;

export const Head = styled.div<{ $color?: string , isShort:boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  background: var(--Primary-400, #418DB8);
  position: sticky;
  top: 0;
  z-index: 2;
  padding-left: 6px;
  min-width: ${({ isShort }) => (isShort ? '1200px' : '1680px')};

  &::before {
    content: "";
    min-width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ $color }) => $color && css`
    &::before {
      background: ${$color};
    }
  `}
`;

const sizes = ["24.60", "8.09", "7.26", "5.77", "5.77", "7.03", "8.40", "8.17", "6.63", "7.66", "7.77"];

let nthChildStyles = '';

for (let i = 0; i < sizes.length; i++) {
  nthChildStyles += `
    &:nth-child(${i + 2}) {
      min-width: ${sizes[i]}%;
    }
  `;
}

export const HeadItem = styled.span<{ $center?: boolean }>`
  display: flex;
  align-items: center;
  height: 44px;
  border-right: 1px solid var(--Primary-300, #71AACA);
  color: var(--Generic-White, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  padding: 0 12px;
  width: 100%;
  ${nthChildStyles};

  ${({ $center }) => $center && css`
    text-align: center;
    justify-content: center;
  `}

  &:nth-child(1) {
    min-width: 44px;
    width: 44px;
    padding: 0;
    position: relative;
  }

  &:nth-child(2) {
    min-width: calc(24.60% - 6px);
  }

  &:nth-child(3) {
    padding: 0;
  }
`;

const SpecialButton = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 28px;
  background: transparent;
  padding: 0;
  border: none;
  transform: rotate(-45deg);
  margin-top: -16px;
  margin-left: -10px;
  position: absolute;

  img {
    transform: rotate(45deg);
  }
`;

export const BtnAddCategory = styled.button`
  ${SpecialButton};
  border-bottom: 1px solid var(--Primary-300, #71AACA);
  outline: none;
`;

export const BtnExpand = styled.button`
  ${SpecialButton};
  margin-top: 14px;
  margin-left: 8px;
  outline: none;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  // overflow-y: auto;
`;
