import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  DatePicker,
  Menu,
  Dropdown,
  Modal,
  Button,
  Divider,
} from "antd";
import { ReactComponent as DotsBorderLess } from "assets/svg/dotsBorderLess.svg";
import { PlusOutlined } from "@ant-design/icons";
import moment from "moment";
import DeleteNoteModal from "../Modal/DeleteNoteModal";
import styles from "./styles.module.scss";
import { ReactComponent as Cross } from "assets/svg/close.svg";

import { ReactComponent as EditIcon } from "./edit.svg";

export default function ProjectPlanDetails({
  planDetails,
  handleUpdatePlan,
  handleChangeMain,
  deleteNoteModal,
  handleRemoveNoteModal,
  handleRemoveNotePress,
  loadingNoteDelete,
  handleUpdateGoal,
  handleEditNoteModal,
}) {
  const [state, setState] = useState({
    purpose: planDetails?.purpose || "",
    name: planDetails?.name || "",
    duration: planDetails?.duration || "",
    description: planDetails?.description || "",
    start_line: moment(planDetails?.start_line) || moment(),
    dead_line: moment(planDetails?.dead_line) || moment(),
    objective: planDetails?.goal?.name || "",
    idea: planDetails?.idea || "",
    note: planDetails?.note || "N/A",
  });

  const [isEdit, setIsEdit] = useState(false);
  const [isSaveModel, setIsSaveModel] = useState(false);

  const [endDate, setEndDate] = useState();
  const {
    purpose,
    name,
    description,
    objective,
    duration,
    start_line,
    dead_line,
    idea,
  } = state;

  useEffect(() => {
    setEndDate(dead_line);
  }, []);

  const momentDeadLine = moment(dead_line, "YYYY-MM-DD").format("YYYY-MM-DD");
  const momentStartLine = moment(start_line, "YYYY-MM-DD").format("YYYY-MM-DD");

  useEffect(() => {
    if (planDetails) {
      handleChangeState("name", planDetails?.name);
      handleChangeState("objective", planDetails?.goal?.name);
    }
  }, [planDetails]);

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
    if (key === "start_line" || key === "dead_line") {
      handleUpdate(key, moment(value).format("YYYY-MM-DD"));
    } else {
      handleUpdate(key, value);
    }
  };

  const handleChangeState = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleUpdate = (key, value) => {
    const payload = {
      [key]: value,
    };
    // handleUpdatePlan(payload);
  };

  const handleSave = () => {
    const formateData = {
      start_line: moment(start_line).format("YYYY-MM-DD"),
      dead_line: moment(dead_line).format("YYYY-MM-DD"),
      name,
      objective,
      purpose,
      idea,
      description,
    };

    handleUpdatePlan(formateData);
    setIsEdit(false);
    setIsSaveModel(false);
  };

  const closeAll = () => {
    setIsSaveModel(false);
  };

  return (
    <>
      <Modal
        footer={null}
        closable={false}
        onCancel={closeAll}
        title={false}
        open={isSaveModel}
        centered
      >
        <Row justify="space-between mb-3">
          <div className="font-18 text_black text_500">Save Changes?</div>
          <Cross onClick={closeAll} className={"c-pointer"} />
        </Row>

        <h4 className={styles.modelContent}>
          Are you sure you want to save changes to this project plan?
        </h4>

        <h5 className={styles.modelSubContent}>
          “Launch Executive Coaching for 10 Emerging Leaders”
        </h5>

        <div className={styles.modelFooter}>
          <button onClick={handleSave} className={styles.indicates_button}>
            Save
          </button>
        </div>
      </Modal>

      <div className={styles.content}>
        <div className={styles.form}>
          {!isEdit && (
            <button
              className={styles.editButton}
              onClick={() => setIsEdit(true)}
            >
              <EditIcon />
              Edit
            </button>
          )}
          <div className={styles.inputGroup}>
            {isEdit ? (
              <>
                <label className={styles.label}>Project Name*</label>
                <input
                  className={styles.input}
                  value={name}
                  name={"name"}
                  placeholder="Text"
                  onChange={(value) =>
                    handleChangeState(value.target.name, value.target.value)
                  }
                  onBlur={(key, value) =>
                    name ? handleUpdate("name", name) : console.log("key,value")
                  }
                />
                {!name && (
                  <label className={styles.error}>
                    Project Name is required
                  </label>
                )}
              </>
            ) : (
              <div className={styles.editContent}>
                <label className={styles.label}>Project Name</label>
                <span className={styles.label_gray}>{name}</span>
              </div>
            )}
          </div>

          <Row className="mb-4" gutter={16}>
            <Col className="gutter-row" span={12}>
              <div className="mr-2">
                {isEdit ? (
                  <>
                    <div className="text_blue_dark mb-1">Start Date*</div>
                    <DatePicker
                      value={start_line}
                      /* disabledDate={(current) => {
                    let customDate = moment().format("MM/DD/YYYY");
                    return (
                      current && current < moment(customDate, "MM/DD/YYYY")
                    );
                  }} */
                      name={"start_line"}
                      format={"MM/DD/YYYY"}
                      clearIcon={false}
                      style={{ width: "100%" }}
                      onChange={(date) => handleChange("start_line", date)}
                      placeholder="MM.DD.YYYY"
                    />
                  </>
                ) : (
                  <div className={styles.editContent}>
                    <label className={styles.label}>Start Date</label>
                    <span className={styles.label_gray}>
                      {moment(start_line).format("MM/DD/YYYY")}
                    </span>
                  </div>
                )}
              </div>
            </Col>
            <Col className="gutter-row" span={12}>
              {/* <Card className='b-1 detailsCard radius-2' bordered={false}> */}

              {isEdit ? (
                <>
                  <div className="text_blue_dark mb-1">End Date*</div>
                  <DatePicker
                    clearIcon={false}
                    const
                    status={
                      momentDeadLine && momentDeadLine < momentStartLine
                        ? "error"
                        : ""
                    }
                    format={"MM/DD/YYYY"}
                    value={dead_line ? dead_line : endDate}
                    disabledDate={(current) => {
                      // let customDate = moment().format("MM/DD/YYYY");
                      return (
                        current &&
                        current < moment(start_line, "MM/DD/YYYY") /*  ||
                    (current && current < moment(customDate, "MM/DD/YYYY")) */
                      );
                    }}
                    style={{ width: "100%" }}
                    name={"dead_line"}
                    onChange={(date) => handleChange("dead_line", date)}
                    placeholder="MM/DD/YYYY "
                  />
                </>
              ) : (
                <div className={styles.editContent}>
                  <label className={styles.label}>End Date</label>
                  <span className={styles.label_gray}>
                    {moment(dead_line).format("MM/DD/YYYY")}
                  </span>
                </div>
              )}

              {/* </Card> */}
            </Col>
          </Row>

          {/* <Card className='mt-1 b-1 detailsCard radius-2' bordered={false}> */}
          {/* <div className='mb-2'>
            <div className='text_blue_dark mb-1'>Duration</div>
            <AppInput
              value={duration}
              name='duration'
              onChange={handleChangeState}
              onBlur={handleUpdate}
            />
          </div> */}

          <div className={styles.inputGroup}>
            {isEdit ? (
              <>
                <label className={styles.label}>Objective</label>
                <label className={styles.subLabel}>
                  What do you plan to achieve at the end of this project?
                </label>
                <textarea
                  className={styles.input}
                  value={objective || ""}
                  name={"objective"}
                  onChange={(event) => {
                    let input = event.target.value;

                    if (input.length > 255) {
                      input = input.slice(0, 255);
                    }

                    handleChangeState(event.target.name, input);
                  }}
                  onBlur={() => handleUpdateGoal("name", objective)}
                />
              </>
            ) : (
              <div className={styles.editContent}>
                <label className={styles.label}>Objective</label>
                <span className={styles.label_gray}>{objective || "/"}</span>
              </div>
            )}
          </div>

          <div className={styles.inputGroup}>
            {isEdit ? (
              <>
                <label className={styles.label}>Purpose</label>
                <label className={styles.subLabel}>
                  Why are you taking on this project?
                </label>
                <textarea
                  className={styles.input}
                  value={purpose || ""}
                  name={"purpose"}
                  onChange={(event) => {
                    let input = event.target.value;

                    if (input.length > 255) {
                      input = input.slice(0, 255);
                    }

                    handleChangeState(event.target.name, input);
                  }}
                  onBlur={() => handleUpdate("purpose", purpose)}
                />
              </>
            ) : (
              <div className={styles.editContent}>
                <label className={styles.label}>Purpose</label>
                <span className={styles.label_gray}>{purpose || "/"}</span>
              </div>
            )}
          </div>

          <div className={styles.inputGroup}>
            {isEdit ? (
              <>
                <label className={styles.label}>Brainstorm Ideas</label>
                <label className={styles.subLabel}>
                  List creative ideas, strategies, and approaches to tackle this
                  project.
                </label>
                <textarea
                  className={styles.input}
                  value={idea || ""}
                  name={"idea"}
                  onChange={(value) =>
                    handleChangeState(value.target.name, value.target.value)
                  }
                  onBlur={() => handleUpdate("idea", idea)}
                />
              </>
            ) : (
              <div className={styles.editContent}>
                <label className={styles.label}>Brainstorm Ideas</label>
                <span className={styles.label_gray}>{idea || "/"}</span>
              </div>
            )}
          </div>

          <div className={styles.inputGroup}>
            {isEdit ? (
              <>
                <label className={styles.label}>Description</label>
                <label className={styles.subLabel}>
                  What’s your project about?
                </label>
                <textarea
                  className={styles.input}
                  name={"description"}
                  value={description || ""}
                  multiple
                  onChange={(value) =>
                    handleChangeState(value.target.name, value.target.value)
                  }
                  onBlur={() => handleUpdate("description", description)}
                ></textarea>
              </>
            ) : (
              <div className={styles.editContent}>
                <label className={styles.label}>Description</label>
                <span className={styles.label_gray}>{description || "/"}</span>
              </div>
            )}
          </div>

          {isEdit && (
            <div className={styles.footer}>
              <span className={styles.indicates}>
                *Indicates required field.
              </span>

              <button
                onClick={() => {
                  setIsSaveModel(true);
                }}
                className={styles.indicates_button}
              >
                Save
              </button>
            </div>
          )}
        </div>

        <div className={styles.sidebar}>
          {planDetails?.goal?.notes?.map((note, index) => (
            <div className={styles.note}>
              <div className={styles.head}>
                <h4>Notes</h4>
                <Dropdown
                  trigger={["click"]}
                  className={styles.menuDots}
                  overlay={
                    <Menu
                      className="removeOption"
                      items={[
                        {
                          key: "1",
                          onClick: () =>
                            handleEditNoteModal(note?.id, note?.context),
                          label: (
                            <span style={{ color: "#1B2A3D" }}>Edit Note</span>
                          ),
                        },
                        {
                          key: "2",
                          onClick: () => handleRemoveNoteModal(note?.id),
                          label: (
                            <span style={{ color: "#f00" }}>Delete Note</span>
                          ),
                        },
                      ]}
                    />
                  }
                >
                  <DotsBorderLess className="" />
                </Dropdown>
              </div>
              <span>{moment(note?.created_at).fromNow()}</span>
              <p className="c-word-break">{note?.context}</p>
            </div>
          ))}

          <div
            className={styles.addNote}
            onClick={() => {
              handleChangeMain("noteModal", true);
              handleChangeMain("notetype", "goal");
            }}
          >
            <PlusOutlined />
            <p>Add new note</p>
          </div>
        </div>
      </div>

      <DeleteNoteModal
        visible={deleteNoteModal}
        heading={"Delete Note"}
        onClose={() => handleChangeMain("deleteNoteModal", false)}
        descriptions={"Are you sure you want to delete the note?"}
        handleRemove={handleRemoveNotePress}
        loading={loadingNoteDelete}
      />
    </>
  );
}
