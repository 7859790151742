import { Tooltip } from "antd";
import exclamationIcon from "assets/svg/exclamation.svg";

const ErrorTooltip = ({ title }: { title: string }) => {
  return (
    <Tooltip
      title={title}
      className="c-pointer exclamation_error"
      color="white"
      placement="right"
      overlayClassName="error-tooltip"
    >
      <img src={exclamationIcon} alt="" />
    </Tooltip>
  );
};

export default ErrorTooltip;
