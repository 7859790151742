import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";

const WeightInput = ({ value, handleChangeWeight, record }) => {
  const inputRef = useRef(null);
  const [weight, setWeight] = useState();

  const handleOnChange = (e) => {
    const newValue = Number(e.target.value);

    if (newValue > 100) {
      toast("Weight should be less than 100%", {
        type: "error",
      });
    } else {
      setWeight(newValue);
      handleChangeWeight(newValue / 100, record);
    }
  };

  useEffect(() => {
    setWeight(value);
  }, [value]);

  return (
    <input
      ref={inputRef}
      type="number"
      value={weight}
      onChange={handleOnChange}
    />
  );
};

export default WeightInput;
