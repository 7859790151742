import React, { useState, useEffect, useRef } from "react";
import cc from "classnames";
import { AppLayout } from "components/index";
import classes from "./CreateDecision.module.scss";
import ChooseDecisionHeader from "scenes/DecisionMatrix/components/ChooseDecisionHeader/ChooseDecisionHeader";
import DecisionTemplate from "scenes/DecisionMatrix/components/decisionTemplateCard/decisionTemplateCard";
import {
  PurposeModal,
} from "scenes/DecisionMatrix/components";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ListTemplate } from "redux/DecisionMatrix/ListTemplates/action";
import FormInput from "components/FormInput/FormInput";
import * as yup from "yup";
import { Formik } from "formik";
import { CreateDecision } from "redux/DecisionMatrix/AssignDecision/action";
import { useNavigate } from "react-router-dom";
import HelpPopup from "components/HelpPopup";
import moment from "moment";
import { useProject } from "../../../../utils/utility";
import { PROJECT } from "../../../../utils/constants";

const CreateDecisions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let decision = "";

  const [isCustomDecision, setIsCustomDecision] = useState(false);
  const [tempListData, setTempListData] = useState();
  const [isPurposeModal, setIsPurposeModal] = useState(false);

  const { project } = useProject();

  const $form = useRef(null);

  const {
    user: { data: userData },
  } = useSelector((state) => state.auth);

  const { success: listTemplates } = useSelector(
    (state) => state.getListTemplateReducer
  );

  useEffect(() => {
    dispatch(ListTemplate({ is_allocator: project === PROJECT.AT }));
  }, [project]);

  useEffect(() => {
    if (!listTemplates) return;
    setTempListData(listTemplates);
  }, [listTemplates]);

  const { loading: createDecisionLoading } = useSelector(
    (state) => state.createDecisionReducer
  );

  const schema = yup.object({
    problem: yup
      .string()
      .required("This is a required field")
      .max(250, "Only 250 characters are allowed")
      .matches(/[^\s*].*[^\s*]/g, "This field cannot contain only blankspaces"),
    description: yup
      .string()
      .max(500, "Only 500 characters are allowed")
      .required("This is a required field")
      .matches(/^\S.*\S$|^\S$/, "This field cannot contain only blankspaces"),
    purpose: yup
      .string()
      .max(500, "Only 500 characters are allowed")
      .required("This is a required field")
      .matches(/^\S.*\S$|^\S$/, "This field cannot contain only blankspaces"),
    priority: yup.number().required("This is a required field"),
    // startline: yup.date().min(new Date(), "Startline date must be today or later").required("This is a required field"),
    // deadline: yup.date().min(yup.ref("startline"), "Deadline date cannot be before startline date").required("This is a required field"),
    startline: yup
      .date()
      .required("This is a required field")
      .test(
        'not-past',
        "Startline cannot be in the past",
        value => moment().startOf('day').isSameOrBefore(moment(value))
      )
      .when("deadline", (deadline, schema) =>
        deadline ? schema.max(moment(deadline), "Startline must be earlier than the deadline") : schema
      ),
    deadline: yup
      .date()
      .required("This is a required field")
      .min(yup.ref("startline"), "Deadline must be greater than the startline"),

    // goal: yup.number(),
    /* num_criteria: !decision
      ? yup
          .number()
          .min(3, "Value must be greater than or equal to 3.")
          .typeError("The amount invalid")
          .required("Enter this field")
          .test(
            "no-leading-zero",
            "Leading zero is not allowed",
            (value, context) => {
              return (
                context.originalValue && !context.originalValue.startsWith("0")
              );
            }
          )
      : yup.number(), */
  });

  const onFormSubmit = async (values, action) => {
    if (values && !decision) {
      if (action === "sample") {
        dispatch(
          CreateDecision(
            { ...values, num_criteria: 5, is_sample: true },
            handleCallback
          )
        );
      } else {
        dispatch(CreateDecision({ ...values, num_criteria: 5 }, handleCallback));
      }
    }
  };

  const handleCallback = data => {
    navigate(`/${project}/${data?.id}`);
  }

  const handleApplyAi = ({ key, value }) => {
    console.log({ key, value });
    $formik?.current?.setFieldValue(key, value);
    setIsPurposeModal(false);
  };

  let dtToday = new Date();

  let month = dtToday.getMonth() + 1;
  let day = dtToday.getDate();
  let year = dtToday.getFullYear();

  if (month < 10) month = "0" + month.toString();
  if (day < 10) day = "0" + day.toString();

  var minDate = year + "-" + month + "-" + day;

  let submitAction = undefined;
  const getDays = (startline, deadline) => {
    const days = Math.ceil(
      (Date.parse(deadline) - Date.parse(startline)) / 1000 / 3600 / 24 || 0
    );
    if (days < 0) return "Invalid date selection";
    return `${days} days.`;
  };

  return (
    <div>
      <div className={cc(classes.body)}>
        <AppLayout>
          <ChooseDecisionHeader />

          <HelpPopup />

          {/* {loading ? (
            <AppLoading />
          ) : ( */}

          <div className={classes.mainContainer}>
            <div className={classes.templateContainer}>
              {isCustomDecision ? (
                <>
                  <h2>Custom Decision </h2>

                  <div className={classes.customForm}>
                    <>
                      <div className={classes.container}>
                        <Formik
                          initialValues={{
                            problem: decision?.problem || "",
                            description: decision?.description || "",
                            purpose: decision?.purpose || "",
                            startline: decision?.startline || "",
                            deadline: decision?.deadline || "",
                            // num_criteria: decision?.num_criteria || '5',
                            priority: decision?.priority
                          }}
                          validationSchema={schema}
                          validateOnChange={true}
                          validateOnBlur={true}
                          onSubmit={(values) => {
                            onFormSubmit(values, submitAction);
                          }}
                          // onSubmit={onFormSubmit}
                          innerRef={$form}
                        >
                          {({
                            handleSubmit,
                            setFieldValue,
                            isValid,
                            values,
                            errors,
                            touched,
                            handleBlur,
                          }) => (
                            <form>
                              <Row gutter={8}>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      height="44px"
                                      label={"Decision*"}
                                      name={"problem"}
                                      placeholder={"What is the problem?"}
                                      error={
                                        touched.problem && errors.problem
                                          ? errors.problem
                                          : null
                                      }
                                      setFieldValue={setFieldValue}
                                      value={values.problem}
                                      title={values.problem}
                                      onBlur={handleBlur}
                                    />
                                  </div>
                                </Col>
                                <Col span={4}>
                                  <div className={`${classes.inputField}`}>
                                    <FormInput
                                      height="44px"
                                      label={"Priority*"}
                                      name={"priority"}
                                      error={
                                        touched.priority && errors.priority
                                          ? errors.priority
                                          : null
                                      }
                                      setFieldValue={setFieldValue}
                                      value={values.priority}
                                      type="select"
                                      options={[
                                        { value: "", label: "Select Type", disabled: true },
                                        { value: 3, label: "High" },
                                        { value: 2, label: "Medium" },
                                        { value: 1, label: "Low" },
                                      ]}
                                    />
                                  </div>
                                </Col>

                                <Col span={4}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      onBlur={handleBlur}
                                      height="44px"
                                      label={"Startline*"}
                                      name={"startline"}
                                      placeholder={"YYYY-MM-DD"}
                                      error={
                                        touched.startline && errors.startline
                                          ? errors.startline
                                          : null
                                      }
                                      type={"date"}
                                      setFieldValue={setFieldValue}
                                      value={values.startline}
                                      min={minDate}
                                      max={values?.deadline}
                                    />
                                  </div>
                                </Col>
                                <Col span={4}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      height="44px"
                                      onBlur={handleBlur}
                                      label={"Deadline*"}
                                      name={"deadline"}
                                      placeholder={"YYYY-MM-DD%"}
                                      error={
                                        touched.deadline && errors.deadline
                                          ? errors.deadline
                                          : null
                                      }
                                      type={"date"}
                                      max={"100"}
                                      setFieldValue={setFieldValue}
                                      value={values.deadline}
                                      min={values?.startline || minDate}
                                    />
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <div className={classes.duration_container}>
                                      <label>Duration</label>
                                      {values?.startline && values.deadline ? (
                                        <p>
                                          {getDays(
                                            values.startline,
                                            values.deadline
                                          )}
                                        </p>
                                      ) : (
                                        <p className={classes.warning}>
                                          Startline & Deadline not selected
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row gutter={8} className="mt-2 ">
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      onBlur={handleBlur}
                                      style={{ minHeight: "66px" }}
                                      type="textarea"
                                      label={"Objective*"}
                                      name={"description"}
                                      generateWithAIClick={() => {
                                        setIsPurposeModal("Objectives");
                                      }}
                                      placeholder={"Enter Your Description"}
                                      error={
                                        touched.description &&
                                        errors.description
                                          ? errors.description
                                          : null
                                      }
                                      setFieldValue={(n, v) => {
                                        let input = v;

                                        if (input.length > 255) {
                                          input = input.slice(0, 255);
                                        }

                                        setFieldValue(n, input);
                                      }}
                                      value={values.description}
                                    />
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className={classes.inputField}>
                                    <FormInput
                                      onBlur={handleBlur}
                                      style={{ minHeight: "66px" }}
                                      type="textarea"
                                      label={"Purpose*"}
                                      name={"purpose"}
                                      placeholder={"Enter Your Description"}
                                      generateWithAIClick={() => {
                                        setIsPurposeModal("Purpose");
                                      }}
                                      error={
                                        touched.purpose && errors.purpose
                                          ? errors.purpose
                                          : null
                                      }
                                      setFieldValue={(n, v) => {
                                        let input = v;

                                        if (input.length > 255) {
                                          input = input.slice(0, 255);
                                        }

                                        setFieldValue(n, input);
                                      }}
                                      value={values.purpose}
                                    />
                                  </div>
                                </Col>
                                {/* <Col span={12}>
                                  <div
                                    className={classes.inputField_collaborator}
                                  >
                                    {!decision && (
                                      <FormInput
                                        onBlur={handleBlur}
                                        style={{
                                          width: "5rem",
                                          display: "flex",
                                        }}
                                        height="44px"
                                        label={"Criteria "}
                                        name={"num_criteria"}
                                        placeholder={"Add"}
                                        error={errors.num_criteria}
                                        setFieldValue={setFieldValue}
                                        value={
                                          values.num_criteria === 0
                                            ? ""
                                            : values.num_criteria
                                        }
                                        type={"number"}
                                        min="3"
                                      />
                                    )}
                                  </div>
                                </Col> */}
                              </Row>
                              <div className={classes.btnContainer}>
                                {userData.is_superuser && (
                                  <button
                                    type="button"
                                    className={cc(
                                      classes.btnSubmit,
                                      classes.secondary
                                    )}
                                    disabled={createDecisionLoading || !isValid}
                                    onClick={() => {
                                      submitAction = "sample";
                                      handleSubmit();
                                    }}
                                    name="sample"
                                  >
                                    CREATE AS A SAMPLE
                                  </button>
                                )}

                                <button
                                  type="button"
                                  className={classes.btnSubmit}
                                  disabled={createDecisionLoading/*  || !isValid */}
                                  onClick={() => {
                                    submitAction = "default";
                                    handleSubmit();
                                  }}
                                  name="default"
                                >
                                  CREATE DECISION
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </>
                  </div>
                </>
              ) : (
                <div className={classes.customDecisionContainer}>
                  <button
                    className={classes.create}
                    onClick={() => setIsCustomDecision(!isCustomDecision)}
                    type="button"
                  >
                    {project === PROJECT.AT ? "CREATE BLANK/CUSTOM DECISION" : "CREATE BLANK DECISION"}
                  </button>
                  <span>OR</span>
                  <h3>{project === PROJECT.AT ? "Choose From My Templates" : "Select an Arootah Template"}</h3>
                </div>
              )}

              <div className={classes.decisionTemplateContainer}>
                <div className={classes.border_top}></div>
                <div className={classes.container}>
                  <div className={classes.decision_container}>
                    {tempListData?.map((item, i) => (
                      <DecisionTemplate key={i} template={item} />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* )} */}
        </AppLayout>
      </div>
      {!!isPurposeModal && (
        <PurposeModal
          handleApplyAi={(res) => {
            handleApplyAi(res);
          }}
          defaultValue={isPurposeModal}
          decisionID={decision?.id}
          decisionTitle={decision?.problem}
          onClose={() => setIsPurposeModal(false)}
        />
      )}
    </div>
  );
};

export { CreateDecisions };
