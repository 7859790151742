import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { Dropdown, Tooltip } from "antd";
import { useNavigate, Link, useLocation } from "react-router-dom";

import styles from "./styles.module.scss";
import cc from "classnames";
import RoundedProgress from "./components/RoundProgress";
import GoalSettings from "scenes/ProjectPlanner/components/GoalSettings";

import { ReactComponent as DotsIcon } from "assets/svg/dots.svg";
import { ReactComponent as CollapseIcons } from "assets/svg/collapseWhite.svg";
import { ReactComponent as CollapseIcon } from "assets/svg/collapse.svg";
import { ReactComponent as BackIcon } from "assets/svg/Back.svg";
import { ReactComponent as InfoWhite } from "assets/svg/infoSmall.svg";
import { ReactComponent as ShortViewIcon } from "assets/svg/shortView.svg";
import { ReactComponent as LongViewIcon } from "assets/svg/longView.svg";

import { CATEGORY_INFO, GOAL_INFO, PLAN_INFO } from "constants/index";

import moment from "moment";

import Cookies from "js-cookie";

const PlanHeader = ({
  isShared,
  handleUpdate,
  handleChange,
  handleArchiveModal,
  handleRemoveModal,
  handleUpdateGoal,
  setIsExpandAllNotes,
  isExpandAllNotes,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const isShortView = Cookies.get("project_planner_tableShort") === "true";
  const [view, setView] = useState(isShortView);
  const [isView, setIsView] = useState(false);

  const [isExpand, setIsExpand] = useState(true);
  const [activeTextTab, setActiveTextTab] = useState(1);
  const [textEdit, setTextEdit] = useState(false);

  const [objectiveCopy, setObjectiveCopy] = useState("");
  const [purposeCopy, setPurposeCopy] = useState("");

  const $objectiveRef = useRef(null);
  const $purposeRef = useRef(null);
  const $saveRef = useRef(null);
  const $buttonsRef = useRef([]);

  const { planDetails } = useSelector((state) => state.plan.plan);

  useEffect(() => {
    setObjectiveCopy(planDetails?.goal?.name);
    setPurposeCopy(planDetails?.purpose);
  }, [planDetails?.goal?.name, planDetails?.purpose]);

  const onToggleClickHandle = () => {
    setIsExpand(!isExpand);
  };

  const onObjectiveBlurHandle = (evt) => {
    handleUpdateGoal("name", objectiveCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTimeout(() => {
        setTextEdit(false);
      }, 100);
    }
  };

  const onPurposeBlurHandle = (evt) => {
    handleUpdate("purpose", purposeCopy);

    if (evt.relatedTarget !== $saveRef.current) {
      setTimeout(() => {
        setTextEdit(false);
      }, 100);
    }
  };

  // useEffect(() => {
  //   setTimeout(() => {
  //     if (
  //       textEdit &&
  //       $objectiveRef &&
  //       $objectiveRef.current &&
  //       activeTextTab === 1
  //     ) {
  //       $objectiveRef.current.focus();
  //     } else if (
  //       textEdit &&
  //       $purposeRef &&
  //       $purposeRef.current &&
  //       activeTextTab === 2
  //     ) {
  //       $purposeRef.current.focus();
  //     }
  //   }, 50);
  // }, [textEdit]);

  const title = planDetails?.name;

  const menuItems = [
    {
      key: "1",
      onClick: () => handleChange("renamePlanModal", true),
      label: <span style={{ color: "#1B2A3D" }}>Rename</span>,
    },
    {
      key: "2",
      onClick: handleArchiveModal,
      label: <span style={{ color: "#1B2A3D" }}>Archive Plan</span>,
    },
    {
      key: "3",
      onClick: handleRemoveModal,
      label: <span style={{ color: "#f00" }}>Delete Plan</span>,
    },
  ];

  const menuProps = {
    className: "removeOption",
    items: menuItems,
  };

  const handleCopyChange = (event, setValue, maxLength = 256) => {
    let input = event.target.value;

    if (input.length > maxLength) {
      input = input.slice(0, maxLength);
    }

    setValue(input);
  };

  const navTabs = [
    {
      id: "1",
      visible: true,
      text: "Action Items",
      to: `/project-planner/plan-details/${planDetails?.id}`,
    },
    {
      id: "2",
      visible: !isShared,
      text: "Project Plan Details",
      to: `/project-planner/plan-details/${planDetails?.id}/details`,
    },
    {
      id: "3",
      visible: !isShared,
      text: "Resources",
      to: `/project-planner/plan-details/${planDetails?.id}/resources`,
    },
  ];

  const handleToogleView = () => {
    Cookies.set("project_planner_tableShort", !isShortView);
    setView(!view);
  };

  useEffect(() => {
    if (view) {
      navigate(`${pathname}?short=true`);
    } else {
      navigate(`${pathname}`);
    }
    setIsView(false);
  }, [view, isView]);

  // Handle click outside
  const handleClickOutside = (event) => {
    if (
      !$objectiveRef.current?.contains(event.target) &&
      !$purposeRef.current?.contains(event.target) &&
      !$buttonsRef.current?.some((ref) => ref.contains(event.target))
    ) {
      setTextEdit(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleCollageNotes = () => {
    setIsExpandAllNotes(!isExpandAllNotes);
  };

  return (
    <div className={cc(styles.header)}>
      <div className={cc(styles.topSection, { [styles.active]: isExpand })}>
        <div className={cc(styles.row, styles.main)}>
          <div className={cc(styles.col, styles.border, styles.grow)}>
            <div className={styles.title}>
              {!isShared && (
                <Link className={styles.back} to="/project-planner/">
                  <BackIcon
                    style={{
                      width: 12,
                      height: 12,
                      cursor: "pointer",
                      marginTop: 3,
                      filter: "brightness(0) invert(1)",
                    }}
                  />
                </Link>
              )}

              <Tooltip title={title} className="c-pointer">
                <span
                  className={`${isExpand ? "text-twoline" : "text-oneline"}`}
                >
                  {title}
                </span>
              </Tooltip>

              {!isShared && (
                <Dropdown menu={menuProps} trigger={["click"]}>
                  <button
                    type="button"
                    className={styles.settings}
                    data-testid="project_options_dropdown"
                  >
                    <DotsIcon style={{ filter: "brightness(0) invert(1)" }} />
                  </button>
                </Dropdown>
              )}
            </div>

            <div className={cc(styles.deadline, { [styles.active]: isExpand })}>
              <span>Deadline: </span>
              <span>{moment(planDetails?.dead_line).format("MM/DD/YYYY")}</span>
            </div>
          </div>

          <div
            className={cc(
              styles.col,
              styles.grow,
              styles.border,
              styles.expandHidden,
              { [styles.active]: isExpand }
            )}
          >
            <div
              className={cc(styles.objective, { [styles.active]: isExpand })}
            >
              <div className={styles.objectiveHeader}>
                <div className={styles.objectiveButtons}>
                  <button
                    ref={(el) => ($buttonsRef.current[0] = el)}
                    className={cc({ [styles.active]: activeTextTab === 1 })}
                    type="button"
                    onClick={() => {
                      setActiveTextTab(1);
                      setTextEdit(true);
                    }}
                  >
                    Objective
                  </button>
                  <button
                    ref={(el) => ($buttonsRef.current[1] = el)}
                    className={cc({ [styles.active]: activeTextTab === 2 })}
                    type="button"
                    onClick={() => {
                      setActiveTextTab(2);
                      setTextEdit(true);
                    }}
                  >
                    Purpose
                  </button>
                </div>

                {/* {!isShared && <button
                className={textEdit ? styles.actionBtn : ''}
                type="button"
                ref={$saveRef}
                onClick={() => {
                  setTextEdit(!textEdit);
                }}
              >
                {textEdit ? (
                  "Save"
                ) : (
                  <>
                    <EditIcon />
                    Edit
                  </>
                )}
              </button>} */}
              </div>

              {activeTextTab === 1 && (
                <textarea
                  ref={$objectiveRef}
                  value={objectiveCopy || ""}
                  onChange={(event) =>
                    handleCopyChange(event, setObjectiveCopy, 255)
                  }
                  onBlur={onObjectiveBlurHandle}
                  name={"name"}
                  readOnly={!textEdit}
                />
              )}

              {activeTextTab === 2 && (
                <textarea
                  ref={$purposeRef}
                  value={purposeCopy || ""}
                  onChange={(event) =>
                    handleCopyChange(event, setPurposeCopy, 255)
                  }
                  onBlur={onPurposeBlurHandle}
                  name={"purpose"}
                  readOnly={!textEdit}
                />
              )}
            </div>
          </div>

          <div
            className={cc(
              styles.col,
              styles.grow,
              styles.border,
              styles.expandHidden,
              { [styles.active]: isExpand }
            )}
          >
            <GoalSettings
              isExpand={isExpand}
              isShared={isShared}
              handleChange={handleChange}
              handleUpdateGoal={handleUpdateGoal}
            />
          </div>

          <div
            className={cc(styles.col, styles.grow, styles.progressContainer)}
          >
            <div className={cc(styles.progress, { [styles.active]: isExpand })}>
              <div className={cc(styles.overallProgress)}>
                <p>Overall Progress</p>
              </div>

              <div className={styles.progressBox}>
                <div className={styles.progressWrap}>
                  <RoundedProgress
                    size={40}
                    progress={(
                      Number(planDetails?.plan_progress || 0) * 100
                    ).toFixed(0)}
                    label={
                      (Number(planDetails?.plan_progress || 0) * 100).toFixed(
                        0
                      ) + "%"
                    }
                    strokeWidth={3}
                    innerStrokeWidth={5}
                    circleTwoStroke="#FF9900"
                    fill="transparent"
                  />

                  <span>
                    Plan <br />
                    Progress
                    <Tooltip placement="top" title={PLAN_INFO}>
                      <InfoWhite style={{ marginLeft: 4, cursor: "pointer" }} />
                    </Tooltip>
                  </span>
                </div>

                <div className={styles.progressWrap}>
                  <RoundedProgress
                    size={40}
                    progress={(
                      Number(planDetails?.goal?.progress || 0) * 100
                    ).toFixed(0)}
                    label={
                      (Number(planDetails?.goal?.progress || 0) * 100).toFixed(
                        0
                      ) + "%"
                    }
                    strokeWidth={3}
                    innerStrokeWidth={5}
                    circleTwoStroke="#89C740"
                    fill="transparent"
                  />

                  <span>
                    Goal <br />
                    Progress
                    <Tooltip placement="top" title={GOAL_INFO}>
                      <InfoWhite style={{ marginLeft: 4, cursor: "pointer" }} />
                    </Tooltip>
                  </span>
                </div>

                <div className={styles.progressWrap}>
                  <RoundedProgress
                    size={40}
                    progress={(
                      Number(planDetails?.category_progress || 0) * 100
                    ).toFixed(0)}
                    label={
                      (
                        Number(planDetails?.category_progress || 0) * 100
                      ).toFixed(0) + "%"
                    }
                    strokeWidth={3}
                    innerStrokeWidth={5}
                    circleTwoStroke="#FF8071"
                    fill="transparent"
                  />

                  <span>
                    Results <br />
                    Progress
                    <Tooltip
                      placement="topRight"
                      title={CATEGORY_INFO}
                      align={{
                        offset: [15, 0],
                      }}
                    >
                      <InfoWhite style={{ marginLeft: 4, cursor: "pointer" }} />
                    </Tooltip>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.collapse}>
          <Tooltip placement="left" title="Collapse/Expand">
            <CollapseIcons onClick={onToggleClickHandle} />
          </Tooltip>
        </div>
      </div>

      <div className={cc(styles.bottomSection)}>
        <div className={cc(styles.row, styles.nav)}>
          <div className={styles.col}>
            <div className={styles.navWrapper}>
              {navTabs
                .filter((tab) => tab.visible)
                .map((tab) => (
                  <Link
                    key={tab.id}
                    to={tab.to}
                    onClick={() => {
                      setIsView(true);
                    }}
                    className={cc(styles.navItem, {
                      [styles.active]:
                        pathname.replace(/\/$/, "") ===
                          tab.to.replace(/\/$/, "") || isShared,
                      [styles.disabled]: isShared,
                    })}
                  >
                    {tab.text}
                  </Link>
                ))}
            </div>
          </div>

          <div className={styles.buttonActions}>
            <button
              className={cc(styles.button, styles.viewToggle)}
              type="button"
              onClick={handleCollageNotes}
              data-testid="view-toggle-button"
            >
              <CollapseIcon />
              {isExpandAllNotes ? "Collapse Notes" : "Expand Notes"}
            </button>

            <button
              className={cc(styles.button, styles.viewToggle)}
              type="button"
              onClick={handleToogleView}
              data-testid="view-toggle-button"
            >
              {!view ? <ShortViewIcon /> : <LongViewIcon />}
              {view ? "Long View" : "Short View"}
            </button>

            {/* 
            <button
              type="button"
              className={cc(styles.toggle, { [styles.active]: isExpand })}
              onClick={onToggleClickHandle}
            >
              <BackIcon
                style={{
                  width: 12,
                  height: 12,
                  cursor: "pointer",
                  filter: "brightness(0) invert(1)",
                }}
              />
            </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanHeader;
