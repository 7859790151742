import React, {
  useState,
  FC,
  useEffect,
  useImperativeHandle,
  forwardRef,
  useRef,
  createRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { Accordion } from "react-bootstrap";
import { Button, InputField } from "elements";
import { Select, DatePicker } from "antd";
import cc from "classnames";
import moment, { Moment } from "moment";

import { DeleteItemModal } from "../DeleteItemModal";

import EditIcon from "../../../../assets/svg/edit.svg";
import TrashIcon from "../../../../assets/svg/trash.svg";
import addIcon from "./add.svg";

import {
  Wrapper,
  Col,
  Dragger,
  AddButton,
  // UserPicture,
  InputGroup,
  NameContent,
  NameWrapper,
  NoteWrapper,
  // Delete,
  Toggle,
  NameContainer,
} from "./styled";

// @ts-ignore
import classes from "./styled.module.scss";

import moveIcon from "./move.svg";
import downIcon from "./down.svg";
import plusIcon from "./plus.svg";

import { IItem } from "../@types";

import {
  upateActionItem,
  // @ts-ignore
} from "modules/actions/PlanActions";

// @ts-ignore
import { weightsList, categoryCount, durationList } from "constants";

// @ts-ignore
import { validateEmail } from "utils/utility";
import InviteUserModal from "../InviteUserModal";
import InputWrapper from "../InputWrapper";
import {
  ValidateDeadLineValue,
  ValidateStartLineValue,
} from "../../../../utils/validateValue";
import ErrorTooltip from "../../../../components/ErrorTooltip";
import {
  AddNote,
  NoteContainer,
  NoteContent,
  NoteHeading,
  NoteText,
} from "../Note/styled";
import AddNoteModal from "../../Modal/AddNoteModal";

const { Option } = Select;

interface IProps {
  data?: IItem;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
    category_start_date: string | null;
    category_end_date: string | null;
  };
  isNew?: boolean;
  projectPlanId: number;
  isShared?: boolean;
  isShort: boolean;
  index?: number;
  onCreate?: (values: string) => void;
  isExpandAll?: boolean;
}

interface IHandle {
  focusInput: () => void;
}

interface IDraggableItemProps {
  data: IItem;
  additionalData?: {
    plan_start_date: string | null;
    plan_end_date: string | null;
    category_start_date: string | null;
    category_end_date: string | null;
  };
  projectPlanId: number;
  isShared: boolean;
  isShort: boolean;
  index: number;
  isExpand?: boolean;
}

/* interface INewItemProps {
  projectPlanId: number;
  onCreate: (values: string) => void;
} */

export const DraggableItem: FC<IDraggableItemProps> = ({
  data,
  additionalData,
  projectPlanId,
  isShared,
  isShort,
  index = 0,
  isExpand,
}) => {
  const { id } = data;

  return (
    <Draggable key={id} draggableId={id.toString()} index={index}>
      {(provided) => (
        <Wrapper ref={provided.innerRef} {...provided.draggableProps}>
          <Col>
            <Dragger
              {...provided.dragHandleProps}
              $disabled={isShared}
              title={isShared ? "" : "Drag to reorder..."}
              data-testid="drag-button"
            >
              <img src={moveIcon} alt="" />
            </Dragger>
          </Col>

          <Item
            data={data}
            additionalData={additionalData}
            projectPlanId={projectPlanId}
            isShared={isShared}
            isShort={isShort}
            isExpandAll={isExpand}
          />
        </Wrapper>
      )}
    </Draggable>
  );
};

export const NewItem = forwardRef<IHandle, IProps>(
  ({ projectPlanId, isShort, onCreate }, ref) => {
    const $itemRef = useRef<IHandle>(null);

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        $itemRef.current?.focusInput();
      },
    }));

    const handleClick = () => {
      $itemRef.current?.focusInput();
    };

    return (
      <Wrapper>
        <Col>
          <AddButton
            onClick={handleClick}
            title="Add new action item."
            data-testid="add-button"
          >
            <img src={plusIcon} alt="" />
          </AddButton>
        </Col>

        <Item
          isNew
          projectPlanId={projectPlanId}
          isShort={isShort}
          onCreate={onCreate}
          ref={$itemRef}
        />
      </Wrapper>
    );
  }
);

const Item = forwardRef<IHandle, IProps>(
  (
    {
      data,
      additionalData,
      isNew,
      projectPlanId,
      isShared = false,
      isShort,
      onCreate,
      isExpandAll,
    },
    ref
  ) => {
    const {
      id,
      name,
      duration,
      weight,
      plan_progress,
      category_progress,
      responsible_person,
      start_line,
      dead_line,
      start,
      current,
      target,
      notes,
    } = data || ({} as IItem);

    const dispatch = useDispatch();

    const [isEdit, setIsEdit] = useState(false);
    const [itemName, setItemName] = useState("");
    const [emailToSearch, setEmailToSearch] = useState("");
    const [emailToInvite, setEmailToInvite] = useState<string | null>(null);
    const [isExpand, setIsExpand] = useState(true);
    const [isAddNote, setIsAddNote] = useState(false);
    const [isNoteModel, setIsNoteModel] = useState(false);
    const [isEditNote, setIsEditNote] = useState(false);

    useEffect(() => {
      if (isExpandAll) {
        setIsExpand(true);
      } else {
        setIsExpand(false);
      }
    }, [isExpandAll]);

    const [isDeleted, setIsDeleted] = useState(false);

    const { users: planUsers } = useSelector(
      (state: any) => state.user.projectOwners
    );

    const selectedOwner = planUsers.find(
      (owner: { id: number }) => owner?.id === responsible_person?.id
    );
    const otherOwners = planUsers.filter(
      (owner: { id: number }) => owner?.id !== responsible_person?.id
    );
    const orderedPlanUsers = selectedOwner
      ? [selectedOwner, ...otherOwners]
      : planUsers;

    const $inputRef = createRef<HTMLInputElement>();

    useImperativeHandle(ref, () => ({
      focusInput: () => {
        setIsEdit(true);
      },
    }));

    useEffect(() => {
      if (isEdit && $inputRef.current) {
        $inputRef.current.focus();
      }
    }, [isEdit, $inputRef]);

    const handleEdit = () => {
      setItemName(name || "");
      setIsEditNote(true);
      setIsNoteModel(true);
    };

    const handleBlur = () => {
      setIsEdit(false);
      changeName();
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        setIsEdit(false);
        changeName();
      } else if (e.key === "Escape") {
        setIsEdit(false);
        setItemName(name || "");
      }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let input = e.target.value;

      if (input.length > 255) {
        input = input.slice(0, 255);
      }

      setItemName(input);
    };

    const changeName = () => {
      if (!itemName) return;

      if (isNew && onCreate) {
        onCreate(itemName);
        setItemName("");
      } else {
        dispatch(upateActionItem(id, { name: itemName }, projectPlanId, true));
      }
    };

    const startLineDate = additionalData?.category_start_date;
    const endLineDate = additionalData?.category_end_date;

    const isDateDisabled = (
      current: Moment,
      name: "start_line" | "dead_line"
    ): boolean => {
      if (!current) return false;

      if (name === "start_line") {
        if (
          (additionalData?.plan_start_date &&
            current.isBefore(moment(additionalData.plan_start_date), "day")) ||
          (additionalData?.category_start_date &&
            current.isBefore(moment(additionalData.category_start_date), "day"))
        ) {
          return true;
        }
        if (
          (additionalData?.plan_end_date &&
            current.isAfter(moment(additionalData.plan_end_date), "day")) ||
          (additionalData?.category_end_date &&
            current.isAfter(moment(additionalData.category_end_date), "day"))
        ) {
          return true;
        }
      } else if (name === "dead_line") {
        if (
          (additionalData?.plan_start_date &&
            current.isBefore(moment(additionalData.plan_start_date), "day")) ||
          (additionalData?.category_start_date &&
            current.isBefore(moment(additionalData.category_start_date), "day"))
        ) {
          return true;
        }
        if (
          (additionalData?.plan_end_date &&
            current.isAfter(moment(additionalData.plan_end_date), "day")) ||
          (additionalData?.category_end_date &&
            current.isAfter(moment(additionalData.category_end_date), "day"))
        ) {
          return true;
        }

        if (start_line && current <= moment(start_line)) {
          return true;
        }
      }

      return false;
    };

    const startLineError = ValidateStartLineValue(
      start_line || null,
      startLineDate || null,
      endLineDate || null
    );

    const deadLineError = ValidateDeadLineValue(
      dead_line || null,
      startLineDate || null,
      endLineDate || null,
      start_line || null
    );

    const notesData = notes ? notes[0] : null;
    const UserName =
      responsible_person?.first_name && responsible_person?.last_name
        ? responsible_person?.first_name.substring(0, 1) +
          responsible_person?.last_name.substring(0, 1)
        : responsible_person?.name
        ? responsible_person?.name.split(" ").length > 1
          ? responsible_person.name.split(" ")[0].substring(0, 1) +
            responsible_person.name.split(" ")[1].substring(0, 1)
          : responsible_person.name.substring(0, 2)
        : responsible_person?.email?.substring(0, 2);

    return (
      <>
        <DeleteItemModal
          itemId={id}
          itemName={name ?? ""}
          planId={projectPlanId}
          type="action"
          isVisible={isDeleted}
          onClose={() => setIsDeleted(false)}
        />

        <AddNoteModal
          data={notesData}
          isVisible={isNoteModel}
          isName={itemName}
          isEdit={isEditNote}
          isAdd={isAddNote}
          actionItemId={id}
          planId={projectPlanId}
          onClose={() => {
            setIsNoteModel(false);
            setIsEditNote(false);
            setIsAddNote(false);
          }}
        />

        <Accordion as={Col} activeKey={isExpand && !isEdit ? "1" : "0"}>
          <InputGroup>
            <NameContent $disabled={isShared}>
              <NameContainer
                $collapsed={notesData ? !(isExpand && !isEdit) : true}
              >
                <NameWrapper>
                  {(!isShared || (isShared && notes?.[0]?.context)) &&
                    !isNew && (
                      <Toggle
                        title="Expand to view a note..."
                        $active={!(isExpand && !isEdit)}
                        data-testid="expand-collapse-note-button"
                        onClick={() => setIsExpand((prev) => !prev)}
                      >
                        <img src={downIcon} alt="" />
                      </Toggle>
                    )}

                  {isEdit && !isShared ? (
                    <InputField
                      name="name"
                      value={itemName}
                      placeholder="Item name"
                      ref={$inputRef}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      onKeyDown={handleKeyDown}
                      data-testid="name-input"
                    />
                  ) : (
                    <span
                      data-testid="name-value"
                      style={{
                        textDecoration:
                          Number(plan_progress) === 10
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {name}
                    </span>
                  )}
                </NameWrapper>

                <div>
                  {isNew ? null : (
                    <>
                      {!isShared && (
                        <NoteContainer className="action-items">
                          {(!notesData || !notesData?.context.length) && (
                            <AddNote
                              onClick={() => {
                                setIsAddNote(true);
                                setIsNoteModel(true);
                              }}
                            >
                              <img src={addIcon} alt="" />
                              Add Note
                            </AddNote>
                          )}

                          <img
                            src={EditIcon}
                            alt=""
                            className="cursor-pointer"
                            onClick={handleEdit}
                          />

                          <img
                            src={TrashIcon}
                            alt=""
                            className="cursor-pointer"
                            onClick={() => setIsDeleted(true)}
                          />
                        </NoteContainer>
                      )}
                    </>
                  )}
                </div>
              </NameContainer>
            </NameContent>

            {notesData && (
              <Accordion.Collapse as={NoteWrapper} eventKey={"1"}>
                <div>
                  {isNew ? null : (
                    <NoteContent>
                      <NoteHeading>Notes :</NoteHeading>
                      <NoteText>{notesData.context}</NoteText>
                    </NoteContent>
                  )}
                </div>
              </Accordion.Collapse>
            )}
          </InputGroup>
        </Accordion>

        <Col>
          {isNew ? null : (
            <Select
              className={cc(classes.dropdown, classes.duration)}
              placeholder="Select duration"
              dropdownMatchSelectWidth={false}
              value={duration === "00:00:00" ? "-" : duration}
              disabled={isShared}
              onChange={(value, _) =>
                dispatch(
                  upateActionItem(id, { duration: value }, projectPlanId, true)
                )
              }
              data-testid="duration-select"
            >
              {durationList.map(
                (count: { value: string; key: string }, index: number) => (
                  <Option key={index} value={count.value}>
                    {count.key}
                  </Option>
                )
              )}
            </Select>
          )}
        </Col>

        <Col className={cc(classes.weightSelect)}>
          {isNew ? null : (
            <Select
              className={cc(classes.dropdown, classes.weight)}
              placeholder="0"
              dropdownMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <div className="weightlist">{menu}</div>
              )}
              value={{ key: weight.toString(), value: weight / 10 }}
              disabled={isShared}
              onChange={(value, _) =>
                dispatch(
                  upateActionItem(
                    id,
                    { weight: Number(value) * 10 },
                    projectPlanId,
                    true
                  )
                )
              }
              data-testid="weight-select"
            >
              {weightsList.map(
                (count: { value: number; key: string }, index: number) => (
                  <Option key={index} value={count.value}>
                    {count.key}
                  </Option>
                )
              )}
            </Select>
          )}
        </Col>

        <Col className={cc(classes.weightSelect)}>
          {isNew ? null : (
            <Select
              className={cc(classes.dropdown, classes.weight)}
              placeholder="0"
              dropdownMatchSelectWidth={false}
              dropdownRender={(menu) => (
                <div className="weightlist">{menu}</div>
              )}
              value={
                plan_progress
                  ? { key: plan_progress.toString(), value: plan_progress }
                  : null
              }
              disabled={isShared}
              onChange={(value, _) =>
                dispatch(
                  upateActionItem(
                    id,
                    { plan_progress: value },
                    projectPlanId,
                    true
                  )
                )
              }
              data-testid="progress-select"
            >
              {categoryCount.map(
                (count: { value: number; key: string }, index: number) => (
                  <Option key={index} value={count.value}>
                    {count.key}
                  </Option>
                )
              )}
            </Select>
          )}
        </Col>

        {isShort ? null : (
          <Col $center data-testid="progress-value">
            {isNew ? "" : `${(Number(category_progress) * 100).toFixed(2)}%`}
          </Col>
        )}

        <Col>
          {isNew ? null : (
            <Select
              onSelect={(value) =>
                dispatch(
                  upateActionItem(
                    id,
                    { responsible_person: value },
                    projectPlanId,
                    true
                  )
                )
              }
              // value={responsible_person?.email?.substring(0, 2)}
              value={UserName}
              onSearch={(e) => setEmailToSearch(e)}
              className={cc(classes.dropdown, classes.person)}
              dropdownMatchSelectWidth={false}
              dropdownStyle={{ minWidth: 200 }}
              size="large"
              showSearch
              popupClassName="testing-popup"
              notFoundContent={
                validateEmail(emailToSearch) ? (
                  <Button
                    onClick={() => {
                      setEmailToInvite(emailToSearch);
                    }}
                  >
                    Invite {emailToSearch}
                  </Button>
                ) : (
                  "No results. Enter email to invite"
                )
              }
              disabled={isShared}
              placeholder="Select user"
              data-testid="owner-select"
              // open
            >
              {orderedPlanUsers?.map((owner: any, index: number) => {
                return (
                  <Option
                    style={{ zIndex: "99999" }}
                    key={index}
                    value={owner?.id}
                    className={
                      owner?.id === responsible_person.id
                        ? classes.selected
                        : ""
                    }
                  >
                    <div className="hello">{owner?.email}</div>
                  </Option>
                );
              })}
            </Select>
          )}
        </Col>

        <Col $center>
          <div className="d-flex align-items-center justify-content-center gap-1">
            {isNew ? null : isShared ? (
              start_line ? (
                moment(start_line).format("YYYY-MM-DD")
              ) : null
            ) : (
              <DatePicker
                className={classes.datepicker}
                format={"MM/DD/YYYY"}
                disabledDate={(current) =>
                  isDateDisabled(current, "start_line")
                }
                value={start_line ? moment(start_line) : null}
                onChange={(_: any, value: string) =>
                  dispatch(
                    upateActionItem(
                      id,
                      {
                        start_line: value
                          ? moment(value).format("YYYY-MM-DD")
                          : null,
                      },
                      projectPlanId,
                      true
                    )
                  )
                }
                data-testid="start-date"
              />
            )}
            {startLineError && <ErrorTooltip title={startLineError || ""} />}
          </div>
        </Col>

        <Col $center>
          {isNew ? null : isShared ? (
            dead_line ? (
              moment(dead_line).format("YYYY-MM-DD")
            ) : null
          ) : (
            <div className="d-flex align-items-center justify-content-center gap-1">
              <DatePicker
                className={classes.datepicker}
                format={"MM/DD/YYYY"}
                disabledDate={(current) => isDateDisabled(current, "dead_line")}
                value={dead_line ? moment(dead_line) : null}
                onChange={(_: any, value: string) =>
                  dispatch(
                    upateActionItem(
                      id,
                      {
                        dead_line: value
                          ? moment(value).format("YYYY-MM-DD")
                          : null,
                      },
                      projectPlanId,
                      true
                    )
                  )
                }
                data-testid="end-date"
              />
              {deadLineError && <ErrorTooltip title={deadLineError || ""} />}
            </div>
          )}
        </Col>

        {isShort ? null : (
          <Col $center>
            {isNew ? null : (
              <InputWrapper
                name="start"
                type="number"
                defaultValue={start || ""}
                placeholder=" - "
                disabled={isShared}
                onChange={(value, name) => {
                  dispatch(
                    upateActionItem(
                      id,
                      { [name]: value ? Number(value).toFixed(2) : null },
                      projectPlanId,
                      true
                    )
                  );
                }}
                data-testid="start-input"
              />
            )}
          </Col>
        )}

        {isShort ? null : (
          <Col $center>
            {isNew ? null : (
              <InputWrapper
                name="current"
                type="number"
                defaultValue={current || ""}
                placeholder=" - "
                disabled={isShared}
                onChange={(value, name) => {
                  dispatch(
                    upateActionItem(
                      id,
                      { [name]: value ? Number(value).toFixed(2) : null },
                      projectPlanId,
                      true
                    )
                  );
                }}
                data-testid="current-input"
              />
            )}
          </Col>
        )}

        {isShort ? null : (
          <Col $center>
            {isNew ? null : (
              <InputWrapper
                name="target"
                type="number"
                defaultValue={target || ""}
                placeholder=" - "
                disabled={isShared}
                onChange={(value, name) => {
                  dispatch(
                    upateActionItem(
                      id,
                      { [name]: value ? Number(value).toFixed(2) : null },
                      projectPlanId,
                      true
                    )
                  );
                }}
                data-testid="target-input"
              />
            )}
          </Col>
        )}

        <InviteUserModal
          isActive={!!emailToInvite}
          emailToInvite={emailToInvite ?? ""}
          onClose={() => setEmailToInvite(null)}
        />
      </>
    );
  }
);
