import React, { useEffect, useState } from "react";
import { Slider, Popover, Checkbox, Col, Row, Modal } from "antd";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { UpdateCriteriaRows } from "redux/DecisionMatrix/UpdateCriteriaRows/action";

import { ScoringRubricModal } from "scenes/DecisionMatrix/components/ScoringRubricModal/scoringRubricModal";
import { ClosePopup } from "redux/DecisionMatrix/GetDecisionDetails/action";

// Todo: delete these from redux?
/* import { DeleteCriteria } from "redux/DecisionMatrix/DeleteCriteria/action";
import { AssignCriteria } from "redux/DecisionMatrix/AssignCriteria/action";
import { UpdateCriteria } from "redux/DecisionMatrix/UpdateCriteria/action";
import { AddCriteriaRows } from "redux/DecisionMatrix/AddCriteriaRows/action"; */

import classes from "./manageCriteria.module.scss";
import cc from "classnames";

import { ReactComponent as CloseIcon2 } from "./close_2.svg";
import { ReactComponent as AddIcon2 } from "./add_2.svg";
import { ReactComponent as OptionsIcon } from "./options.svg";
import { ReactComponent as NextIcon } from "./next_icon.svg";
import { ReactComponent as BackIcon } from "./back.svg";
import { ReactComponent as MoveIcon } from "./move.svg";
import { ReactComponent as CautionIcon } from "./caution.svg";
import { weightDistributionV4 } from "utils/utility";
import { AIGenerateCriteriaModal } from "../AIAssistantModal/AIGenerateCriteriaModal";
import { AIScoringRubicModal } from "../AIAssistantModal/AIScoringRubicModal";
import { AIDefinitionModal } from "../AIAssistantModal/AIDefinitionModal";

const ManageCriteria = () => {
  const { decisionId } = useParams();

  const dispatch = useDispatch();

  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionDetailsReducer
  );

  const { loading: updateLoading } = useSelector(
    (state) => state.updateCriteriaRowsReducer
  );
  const [criterias, setCriterias] = useState([]);
  const [isWeightInput, setIsWeightInput] = useState("");
  const [isShowInactive, setIsShowInactive] = useState(false);
  const [isViewRubric, setIsViewRubric] = useState(null);
  const [isClosing, setIsClosing] = useState(false);
  const [isRubricModal, setIsRubricModal] = useState(false);
  const [selectedCriteria, setSelectedCriteria] = useState(null);
  const [isDefinitionModal, setIsDefinitionModal] = useState(false);
  const [steps, setSteps] = useState(0);
  const [popoverID, setPopoverID] = useState(null);
  const [popover1ID, setPopover1ID] = useState(null);
  const [stepDescriptionsArr, setStepDescriptionsArr] = useState([
    "Establish and Define Criteria",
    "Prioritize Criteria",
    "Weigh criteria",
    "Define Scoring Rubric",
  ]);
  const [ids, setIds] = useState([]);
  const [rubricIds, setRubricIds] = useState([]);
  const [isGenerateCriteriaModal, setIsGenerateCriteriaModal] = useState(false);
  const [ischange, setIsChange] = useState([])

  useEffect(() => {
    if (userDecisions?.criteria) {
      const totalWeight = userDecisions.criteria.reduce((acc, curr) => {
        return curr?.active && !curr?.is_deleted ? acc + curr.weight : acc;
      }, 0);

      // Common transformation for criteria
      const sortedAndMappedCriteria = userDecisions.criteria
        .sort((a, b) => (b?.weight || 0) - (a?.weight || 0))
        .map((c, i) => ({ ...c, order: i }));

      setCriterias(
        totalWeight !== 100
          ? weightDistributionV4(sortedAndMappedCriteria, 100)
          : sortedAndMappedCriteria
      );
    }
  }, [userDecisions?.criteria]);

  useEffect(() => {
    return () => {
      setIds([]);
    };
  }, []);

  const setField = (e, field) => {
    if(e?.title?.length > 0){
      setIsChange((prevIds) => [...prevIds, e?.id])
    } else{
      setIsChange((prevIds) => prevIds.filter((id) => id !== e?.id))
    }
    if (e?.title?.replaceAll('\n', '')?.length > 255) {
      // Function to push a new ID into the state
      setIds((prevIds) => [...prevIds, e?.id]);
    } else {
      // Function to pop the specifi ID from the state
      setIds((prevIds) => prevIds.filter((id) => id !== e?.id))
    }
    if(e?.rubric?.replaceAll('\n', '')?.length > 2000){
      // Function to push a new ID into the state
      setRubricIds((prevIds) => [...prevIds, e?.id]);
    } else {
      // Function to pop the specifi ID from the state
      setRubricIds((prevIds) => prevIds.filter((id) => id !== e?.id))
    }
    setCriterias(
      criterias?.map((criteria) =>
        criteria?.id === e?.id ? { ...criteria, [field]: e[field] } : criteria
      )
    );
  };

  const activeCriterias = criterias?.filter((c) => c?.active && !c?.is_deleted);
  const inactiveCriterias = criterias?.filter(
    (c) => !c?.active && !c?.is_deleted
  );

  const totalWeight = activeCriterias?.reduce((c, i) => (c += i?.weight), 0);

  useEffect(() => {
    if (!activeCriterias)
      return;

    const totalWeight = activeCriterias.reduce((acc, curr) => {
      return acc + curr.weight;
    }, 0);

    if (steps === 2 && !totalWeight === 100) {
      onSliderChangeHandle(activeCriterias[0]?.id)(activeCriterias[0]?.weight);
    }
  }, [steps, activeCriterias]);

  const onSliderChangeHandle = (id) => (value) => {
    let criteriasraw = criterias
      ?.filter((c) => c?.active && !c.is_deleted)
      ?.map((obj) => Object.assign({}, obj));

    let criteriasAbove = [...criteriasraw];
    let criteriasBelow = [...criteriasraw];

    const index = criteriasBelow?.findIndex((obj) => obj?.id === id);

    if (index >= 0) {
      criteriasAbove?.splice(index, criteriasAbove?.length);
      criteriasBelow?.splice(0, index + 1);
    }

    let criteriasAboveTotalWeight = 0;

    for (let i = 0; i < criteriasAbove.length; i++) {
      criteriasAboveTotalWeight += criteriasAbove[i]?.weight;
    }

    let newValue = 100 - value - criteriasAboveTotalWeight;

    criteriasBelow = [
      ...criteriasraw,
      ...weightDistributionV4(criteriasBelow, newValue),
    ];

    const newCriterias = criteriasraw.map((criteria) =>
      criteria.id === id ? { ...criteria, weight: value } : criteria
    );
    const newWeight = newCriterias.reduce(
      (accumulator, currentValue) => accumulator + currentValue.weight,
      0
    );

    if (
      newWeight === 100 &&
      !newCriterias?.find((criteria) => criteria.weight === undefined)
    )
      setCriterias([
        ...criteriasraw.map((criteria) =>
          criteria?.id === id ? { ...criteria, weight: value } : criteria
        ),
        ...[...criterias]?.filter((c) => !(c?.active && !c?.is_deleted)),
      ]);
  };

  const onAddCriteriaHandle = () => {
    const criteriasModified = [
      ...criterias,
      {
        decision: decisionId,
        title: "",
        description: "",
        rubric: "",
        weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
        active: true,
        id: new Date()?.getTime(),
      },
    ];

    const criteriasWeight = criteriasModified.filter(
      (c) => c?.active && !c?.is_deleted
    );
    const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

    setCriterias([
      ...criteriasModified?.filter((c) => c?.is_deleted),
      ...criteriasFinal,
    ]);

    setTimeout(() => {
      const result = document.getElementsByClassName("criteria-name");
      const lastItem = result[result?.length - 1];

      lastItem?.focus();
    }, 150);
  };

  const onShowInactiveToggleHandle = () => {
    setIsShowInactive(!isShowInactive);
  };

  const handleOpenChange = (visible) => {
    if (!visible) {
      setPopoverID(null);
    }
  };

  const DeleteCriteria = (criteria) => {
    setPopoverID(null);

    if (criteria?.created_at) {
      const criteriasPrepare = criterias?.map((c) =>
        c?.id === criteria?.id ? { ...c, is_deleted: true } : c
      );

      const criteriasWeight = criteriasPrepare?.filter(
        (c) => c?.active && !c?.is_deleted
      );
      const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

      setCriterias([
        ...criteriasPrepare?.filter((c) => c?.is_deleted || !c?.active),
        ...criteriasFinal,
      ]);
    } else {
      const criteriasPrepare = criterias?.filter((c) => c?.id !== criteria?.id);

      const criteriasWeight = criteriasPrepare?.filter(
        (c) => c?.active && !c?.is_deleted
      );
      const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

      setCriterias([
        ...criteriasPrepare?.filter((c) => c?.is_deleted || !c?.active),
        ...criteriasFinal,
      ]);
    }
  };

  const deleteCriteriaHandle = criteria => () => {
    let updatedCriteria = criterias?.filter(
      (item) => item?.id !== criteria?.id
    );

    setCriterias(updatedCriteria);
    DeleteCriteria(criteria);
  };

  const onActivateToggleHandle = (criteria) => () => {
    setPopoverID(null);

    const prepareCriterias = criterias?.map((c) =>
      c?.id === criteria?.id ? { ...c, active: !c?.active } : c
    );

    setCriterias([
      ...weightDistributionV4(
        prepareCriterias
          ?.filter((c) => c?.active && !c?.is_deleted)
          ?.map((c, i) => ({ ...c, order: i })),
        100
      ),
      ...prepareCriterias?.filter((c) => !c?.active || c?.is_deleted),
    ]);
  };

  const onRubricSaveHandle = (criteria, rubric) => {
    setCriterias(
      criterias?.map((c) => (c?.id === criteria ? { ...c, rubric } : c))
    );

    setIsViewRubric(null);
  };
  const onDefinitionApplyModal = (criteria, description) => {
    setCriterias(
      criterias?.map((c) =>
        c?.id === criteria?.id ? { ...c, description } : c
      )
    );
    setSelectedCriteria(null);
    setIsDefinitionModal(false);
  };
  const handleOpenChange1 = (visible) => {
    if (!visible) {
      setPopover1ID(null);
    }
  };
  const onDefinitionModal = (criteria) => {
    setPopoverID(null);
    setSelectedCriteria({ id: criteria?.id, title: criteria?.title });
    setIsDefinitionModal(true);
  };
  const saveCriterias = () => {
    const criteriasForCreate = criterias?.filter((c) => !c?.created_at);
    const criteriasForUpdate = criterias?.filter((c) => {
      if (!c?.created_at || c?.is_deleted) return false;

      const o = userDecisions?.criteria?.find((c2) => c2?.id === c?.id);

      if (
        o.title === c.title &&
        o.description === c.description &&
        o.weight === c.weight &&
        o.active === c.active &&
        o.rubric === c.rubric
      )
        return false;

      return true;
    });

    const criteriasForDelete = criterias
      ?.filter((c) => c?.created_at && c?.is_deleted)
      ?.map((c) => c?.id);

    dispatch(ClosePopup(userDecisions?.id));

    dispatch(
      UpdateCriteriaRows({
        create: criteriasForCreate,
        update: criteriasForUpdate,
        delete: criteriasForDelete,
        decision: decisionId,
      })
    );
  };

  const handleStepCount = (steps) => stepDescriptionsArr[steps];

  function moveItemInArray(array, from, to) {
    if (to === from) return array;
    var target = array[from];
    var increment = to < from ? -1 : 1;

    for (var k = from; k != to; k += increment) {
      array[k] = array[k + increment];
    }
    array[to] = target;
    return array;
  }
  const onRubricApplyModal = (criteria, rubric) => {
    setCriterias(
      criterias?.map((c) => (c?.id === criteria?.id ? { ...c, rubric } : c))
    );
    setSelectedCriteria(null);
    setIsRubricModal(false);
  };
  const onRubricModal = (criteria) => {
    setPopover1ID(null);
    setSelectedCriteria({ id: criteria?.id, title: criteria?.title });
    setIsRubricModal(true);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    const modifiedCriteriaArr = moveItemInArray(
      criterias.filter((item) => !item.is_deleted),
      source?.index,
      destination?.index
    );

    setCriterias([
      ...criterias.filter((item) => item?.is_deleted),
      ...weightDistributionV4(
        modifiedCriteriaArr?.map((c, i) => ({ ...c, order: 10 - i })),
        100
      ),
    ]);
  };
  const onAddAICriteriaHandle = (list) => {
    if (!list) return;
    const criteriasModified = [
      ...criterias?.filter((item) => item?.title),
      ...criterias
        ?.filter((item) => !item?.title && !item?.description)
        ?.map((item) => {
          return { ...item, is_deleted: true };
        }),
    ];
    list?.forEach((element) => {
      criteriasModified?.push({
        decision: parseInt(decisionId),
        title: element?.criteria_name,
        description: element?.criteria_description,
        rubric: "",
        weight: 100 - totalWeight > 0 ? 100 - totalWeight : 1,
        active: true,
        id: uuidv4(),
        priority: null,
        order: criteriasModified?.length + 1,
      });
    });
    const criteriasWeight = criteriasModified.filter(
      (c) => c?.active && !c?.is_deleted
    );
    const criteriasFinal = weightDistributionV4(criteriasWeight, 100);

    setCriterias([
      ...criteriasModified.filter((c) => c?.is_deleted || !c?.active),
      ...criteriasFinal,
    ]);

    setIsGenerateCriteriaModal(false);
  };

  const definitionSteps = (criteria, isInactive) => (
    <div className={cc(classes.box, steps === 0 ? classes.box_popup : "")}>
      <textarea
        className={classes.textarea}
        placeholder={"Description..."}
        value={criteria?.description || ""}
        onChange={(e) =>
          setField(
            {
              description: e.target.value,
              id: criteria?.id,
            },
            "description"
          )
        }
      />
      {steps === 0 && (
        <Popover
          placement="left"
          overlayClassName={classes.dropdown}
          open={criteria?.id === popoverID}
          onOpenChange={handleOpenChange}
          content={
            <div className={classes.settings}>
              <button type="button" disabled={isInactive ? false : criterias.filter(item => !item.is_deleted && item.active).length <= 1} onClick={onActivateToggleHandle(criteria)}>
                {isInactive ? "Active" : "Inactive"}
              </button>
              <button type="button" onClick={() => onDefinitionModal(criteria)}>
                Suggest Definition
              </button>
              <button type="button" disabled={isInactive ? false : criterias.filter(item => !item.is_deleted && item.active).length <= 1} onClick={deleteCriteriaHandle(criteria)}>
                Delete
              </button>
            </div>
          }
          trigger="click"
        >
          <span
            onClick={() => setPopoverID(criteria?.id)}
            className={classes.dropdownToggle}
          >
            <OptionsIcon />
          </span>
        </Popover>
      )}
    </div>
  );

  const weightStep = (criteria) => (
    <div className={cc(classes.box, "d-flex align-items-center")}>
      <div className={cc(classes.sliderWrapper, "flex-grow-1")}>
        <div className={classes.slide_header}>
          <p>Weight</p>
          <span
            className={classes.sliderPercentage}
            onClick={() => setIsWeightInput(criteria?.id)}
          >
            {isWeightInput === criteria?.id ? (
              <input
                className={classes.weightInput}
                value={criteria?.weight}
                type="text"
                onChange={(e) => {
                  const value = e?.target?.value;

                  if (isNaN(value) || value.trim() === '') {
                    return false;
                  }

                  setField(
                    {
                      weight: +value,
                      id: criteria?.id,
                    },
                    "weight"
                  )
                }}
                onBlur={() => setIsWeightInput("")}
                onKeyDown={(e) => {
                  if (e.keyCode === 13) {
                    setIsWeightInput("");
                  }
                }}
              />
            ) : (
              <p>{criteria?.weight}%</p>
            )}
          </span>
        </div>
        <Slider
          className="weight_slider"
          value={criteria?.weight}
          defaultValue={criteria?.weight || 0}
          min={1}
          onChange={onSliderChangeHandle(criteria?.id)}
        />
      </div>
    </div>
  );

  const scoreScaleStep = (criteria) => (
    <div className={cc(classes.box, steps === 3 ? classes.box_popup : "")}>
      <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: rubricIds.includes(criteria?.id) ? "10px" : "null" }}>
      <textarea
        className={cc(classes.textarea, classes.score_textarea)}
        placeholder={"6 to 10-Excellent, 3 to 5 Good ..."}
        value={criteria?.rubric || ""}
        onChange={(e) => {
          setField(
            {
              rubric: e?.target?.value,
              id: criteria?.id,
            },
            "rubric"
          );
        }}
        style={{ borderColor: rubricIds.includes(criteria?.id) ? '#f51e14' : null }}
      />
      {rubricIds.includes(criteria?.id) && (<div style={{ color: '#f51e14', fontSize: 12, marginLeft: "5px" }}>Only 2000 characters are allowed.</div>)}
      </div>
      {steps === 3 && (
        <Popover
          placement="left"
          open={popover1ID === criteria?.id}
          onOpenChange={handleOpenChange1}
          overlayClassName={classes.dropdown}
          content={
            <div className={classes.settings}>
              <button type="button" onClick={() => onRubricModal(criteria)}>
                Generate with AI
              </button>
            </div>
          }
          trigger="click"
        >
          <span
            onClick={() => setPopover1ID(criteria?.id)}
            className={classes.dropdownToggle}
          >
            <OptionsIcon />
          </span>
        </Popover>
      )}
    </div>
  );

  const criteriaNumStyleHandler = () => {
    switch (steps) {
      case 0:
        return;
      case 1:
        return classes.disabled_criteria_number;

      default:
        return classes.criteria_number;
    }
  };

  const handleCriteriaTitle = () => {
    switch (steps) {
      case 0:
        return "Definition";
      case 1:
        return "Definition";
      case 2:
        return "Criteria Weight";
      case 3:
        return "Score Scale Details";
      default:
        return "Definition";
    }
  };

  const criteriaHandler = (criteria, steps, isInactive) => {
    switch (steps) {
      case 0:
        return definitionSteps(criteria, isInactive);
      case 2:
        return weightStep(criteria);
      case 3:
        return scoreScaleStep(criteria);
      default:
        return definitionSteps(criteria);
    }
  };

  const NavButton = ({ text, onClick, icon, reverse, disabled }) => (
    <button
      onClick={onClick}
      disabled={disabled}
      style={{
        opacity: disabled ? 0.5 : 1
      }}
      className={cc(
        classes.btn,
        reverse ? classes.reverse_btn : "",
        disabled ? classes.disabled : ""
      )}
      data-testid={reverse ? "next" : "prev"}
    >
      <figure className={cc(classes.next_icon)}
      style={{cursor: disabled ? "unset": "pointer"}}
      >{icon}</figure>
      <span>{text}</span>
    </button>
  );

  const SaveButton = ({ text, savingText, onClick, loading, disabled }) => (
    <div className={classes.save_btn}>
      <button onClick={onClick} disabled={disabled} style={{opacity:disabled ? 0.5 : 1}}>
        
        {loading ? savingText : text}
      </button>
    </div>
  );

  if (!userDecisions || (userDecisions && !userDecisions.is_new))
    return false;

  return (
    <div className={classes.bg}>
      {/* <div className={classes.mainContainer}> */}
      <Modal
        centered
        open={true}
        footer={null}
        bodyStyle={{ padding: "0" }}
        closable={null}
        width={1053}
      >
        <div className={classes.wrapper}>
          {isClosing && (
            <div className={classes.overlay_cancel_container}>
              <CautionIcon />
              <div>
                <p>
                  Closing this popup may result in the loss of unsaved data.
                </p>
                <p>Are you sure you want to proceed?"</p>
              </div>
              <div className={classes.btn_container}>
                <button
                  className={classes.close_button}
                  onClick={() => {
                    dispatch(ClosePopup(userDecisions?.id));
                  }}
                >
                  Close
                </button>
                <button
                  className={classes.cancel_button}
                  onClick={() => setIsClosing(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div className={classes.header}>
            <span>Manage Criteria</span>
            <button
              type="button"
              onClick={() => setIsClosing(true)}
              className={classes.close}
              title="Close"
            >
              <CloseIcon2 />
            </button>
          </div>
          <div className={classes.separator_container}>
            <div className={classes.bg_color}></div>
            <div className={classes.separator}></div>
          </div>

          <div className={classes.details_container}>
            <h6>
              Step {steps + 1}: {handleStepCount(steps)}
            </h6>
            <span>
              Set up your decision by selecting criteria that fulfills your optimal decision.{" "}
            </span>
            {steps === 0 && (
              <div
                onClick={() => setIsGenerateCriteriaModal(true)}
                className={classes.generateWithAI}
              >
                Generate with AI
              </div>
            )}
          </div>

          <div className={classes.body}>
            <div className={classes.tableWrapper}>
              <Row className={cc(classes.table, classes.head)}>
                <Col span={10}>
                  <div className={classes.column}>
                    <div className={cc(classes.title, classes.first)}>
                      <span>Criteria</span>

                      {steps === 0 && (
                        <button
                          type="button"
                          className={classes.buttonAdd}
                          onClick={onAddCriteriaHandle}
                          disabled={loading || updateLoading}
                        >
                          <AddIcon2 />
                          <span>Add Criteria</span>
                        </button>
                      )}
                    </div>
                  </div>
                </Col>
                <Col span={14}>
                  <div className={classes.column}>
                    <div className={classes.title}>
                      <span>{handleCriteriaTitle()}</span>
                      {steps === 2 && (
                        <div
                          className={cc(classes.warning, {
                            [classes.equall]: totalWeight === 100,
                          })}
                        >
                          Sum of all weights: {totalWeight}%
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="characters" direction="vertical">
                  {(provided, snapshot) => (
                    <div
                      className="characters"
                      {...provided.droppableProps}
                      ref={provided?.innerRef}
                    >
                      {criterias &&
                        criterias
                          ?.filter((item) => !item?.is_deleted && item?.active)
                          ?.map((criteria, i) => (
                            <Draggable
                              key={i}
                              draggableId={`${i}`}
                              index={i}
                              isDragDisabled={steps !== 1}
                            >
                              {(swap, snapshot) => (
                                <div
                                  ref={swap.innerRef}
                                  {...swap.draggableProps}
                                  {...swap.dragHandleProps}
                                >
                                  <Row
                                    className={
                                      steps !== 0
                                        ? cc(
                                          classes.colored_table,
                                          classes.table
                                        )
                                        : classes.table
                                    }
                                    key={criteria?.id}
                                  >
                                    <Col span={10}>
                                      <div
                                        className={cc(
                                          classes.box,
                                          classes.first
                                        )}
                                      >
                                        <div
                                          className={classes.criteria}
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          {steps === 1 && <MoveIcon />}
                                          <span
                                            className={criteriaNumStyleHandler()}
                                          >
                                            {i + 1}
                                          </span>
                                          <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: ids.includes(criteria?.id) ? "10px" : "null" }}>
                                            <textarea
                                              className={cc(
                                              classes.textarea,
                                                "criteria-name"
                                              )}
                                              placeholder={"Name..."}
                                              value={criteria?.title}
                                              onChange={(e) =>
                                                setField(
                                                  {
                                                    title: e?.target?.value,
                                                    id: criteria?.id,
                                                  },
                                                  "title"
                                                )
                                              }
                                              style={{ borderColor: ids.includes(criteria?.id) ? '#f51e14' : null }}
                                            >
                                            </textarea>
                                            {ids.includes(criteria?.id) && (<div style={{ color: '#f51e14', fontSize: 12, marginLeft: "5px" }}>Only 255 characters are allowed.</div>)}
                                          </div>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col span={14}>
                                      {criteriaHandler(criteria, steps)}
                                    </Col>
                                  </Row>
                                </div>
                              )}
                            </Draggable>
                          ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            {inactiveCriterias.length && steps === 0 ? (
              <div className={classes.checkbox}>
                <Checkbox
                  onChange={onShowInactiveToggleHandle}
                  checked={isShowInactive}
                >
                  Show inactive criteria
                </Checkbox>
              </div>
            ) : null}

            <div className={`${classes.tableWrapper} mt-0`}>
              {isShowInactive && steps === 0 && (
                <>
                  {inactiveCriterias &&
                    inactiveCriterias?.map((criteria, i) => (
                      <Row
                        className={cc(classes.disabled_table, classes.table)}
                        key={criteria?.id}
                      >
                        <Col span={10}>
                          <div className={classes.box}>
                            <div
                              className={classes.criteria}
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              {steps === 1 && <MoveIcon />}
                              <span className={criteriaNumStyleHandler()}>
                                {i + 1}
                              </span>
                              <input
                                className={classes.input}
                                placeholder={"Name..."}
                                value={criteria.title}
                                onChange={(e) =>
                                  setField(
                                    {
                                      title: e?.target?.value,
                                      id: criteria?.id,
                                    },
                                    "title"
                                  )
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col span={14}>
                          {criteriaHandler(criteria, steps, true)}
                        </Col>
                      </Row>
                    ))}
                </>
              )}
            </div>
          </div>

          <div className={classes.footer}>
            <div>
              {steps > 0 && <NavButton
                text={"Back"}
                icon={<BackIcon />}
                onClick={() => setSteps(steps === 0 ? steps : steps - 1)}
              />}
            </div>
            {steps === stepDescriptionsArr?.length - 1 ? (
              <SaveButton
                disabled={totalWeight !== 100 || loading || updateLoading || rubricIds?.length > 0}
                text={"Save"}
                onClick={saveCriterias}
                loading={loading || updateLoading}
                savingText={"Saving"}
              />
            ) : (
              <NavButton
                text={"Next"}
                icon={<NextIcon />}
                reverse={true}
                disabled={
                  steps === 0 || steps === 1
                    ? criterias[0]?.title.length === 0 || ids.length > 0
                    : (
                      steps === 2
                        ? totalWeight !== 100 || loading || updateLoading || ids.length > 0
                        : ids.length > 0 || ischange.length == 0
                    )
                }
                onClick={() => {
                  if (steps === 2) {
                    const sortedCriterias = criterias?.sort((a, b) => b?.weight - a?.weight);
                    setCriterias(sortedCriterias);
                  }
                  setSteps(
                    stepDescriptionsArr?.length - 1 === steps
                      ? steps
                      : steps + 1
                  )
                }
                }
              />
            )}
          </div>
        </div>
      </Modal>
      {/* </div> */}

      {isGenerateCriteriaModal && (
        <AIGenerateCriteriaModal
          criteria={activeCriterias}
          decisionID={decisionId}
          decision={userDecisions?.problem}
          handleApply={onAddAICriteriaHandle}
          onClose={() => setIsGenerateCriteriaModal(false)}
        />
      )}
      {isRubricModal && (
        <AIScoringRubicModal
          decisionID={decisionId}
          criteria={activeCriterias}
          handleApply={onRubricApplyModal}
          onClose={() => setIsRubricModal(false)}
          selectedCriteria={selectedCriteria}
          onSelectedCriteriaChange={(name, value) => {
            let res = criterias?.find((item) => item?.id == value);
            setSelectedCriteria(res);
          }}
        // onSelectedCriteriaChange={(name, value) => setSelectedCriteria(value)}
        />
      )}
      {isDefinitionModal && (
        <AIDefinitionModal
          decisionID={decisionId}
          criteria={activeCriterias}
          handleApply={onDefinitionApplyModal}
          onClose={() => setIsDefinitionModal(false)}
          selectedCriteria={selectedCriteria}
          // onSelectedCriteriaChange={(name, value) => setSelectedCriteria(value)}
          onSelectedCriteriaChange={(name, value) => {
            let res = criterias?.find((item) => item?.id == value);
            setSelectedCriteria(res);
          }}
        />
      )}
      
      {!!isViewRubric && (
        <ScoringRubricModal
          criteria={activeCriterias}
          onSave={onRubricSaveHandle}
          onClose={() => setIsViewRubric(null)}
        />
      )}
    </div>
  );
};

export { ManageCriteria };
