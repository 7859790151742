import styled from "styled-components";

export const NoteContent = styled.main`
  padding-left: 38px;
`;

export const NoteHeading = styled.h6`
  color: var(--neutral-900, var(--Color-2, #171717));
  font-weight: 400;
  font-size: 10px;
  font-family: Inter;
  margin-top: 9px;
`;

export const NoteContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;

  opacity: 0;
  visibility: hidden;
`;

export const NoteText = styled.span`
  color: var(--Neutral-500, #a3a3a3);
  font-family: Inter;
  font-size: 11px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-word;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  white-space: normal;
  -webkit-line-clamp: 2;
`;

export const AddNote = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: none;
  border: none;
  color: var(--primary-500-main, #1271a6);
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  padding: 0;
`;
