import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import styles from "./decision_matrix.module.scss";
import { Col, Row, Select } from "antd";
import { ReactComponent as ExpandIcon } from "../../../../assets/svg/expand.svg";
import { ReactComponent as AddIcon } from "./plus.svg";
import { AppLoading } from "components/AppLoading";
import cc from "classnames";
import {
  DecisionCard,
  DecisionMatrixLayout,
  Filter,
  Sort,
} from "scenes/DecisionMatrix";

import ArrowIcon from "./arrowIcon";

import useWindowSize from "../../../../utils/useWindowSize";
import { useProject, useAllocatorAccess } from 'utils/utility';
import { EditDecisionModal } from "components/EditDecisionModal/EditDecisionModal";
import { GetDecision } from "redux/DecisionMatrix/GetDecision/action";
import moment from "moment";
import DecisionTemplate from "scenes/DecisionMatrix/components/decisionTemplateCard/decisionTemplateCard";
import { ListTemplate } from "redux/DecisionMatrix/ListTemplates/action";
import HelpPopup from "components/HelpPopup";
import { ListSample } from "redux/DecisionMatrix/ListSamples/action";
import { GetSharedDecisions } from "redux/DecisionMatrix/GetSharedDecisions/action";
import { DecisionSampleCard } from "scenes/DecisionMatrix/components/decisionSampleCard";
// import { ReactComponent as SearchIcon } from "./Search.svg";
import { ReactComponent as SortIcon } from "./Sort.svg";
import { ReactComponent as FilterIcon } from "./Filter.svg";
// import { translate } from "react-range/lib/utils";
// import { SearchOutlined } from "@ant-design/icons";
// import styled from "styled-components";

const DecisionMatrix = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { project } = useProject();
  const isAllocatorUser = useAllocatorAccess();

  const { pathname } = location;

  // console.log('project', project)

  const { success: userDecisions, loading } = useSelector(
    (state) => state.getDecisionReducer
  );
  const { success: listTemplates } = useSelector(
    (state) => state.getListTemplateReducer
  );

  const { success: listSamples } = useSelector(
    (state) => state.getListSamplesReducer
  );

  const { data: sharedDecisions } = useSelector(
    (state) => state.getSharedDecisionsReducer
  );

  const {
    user: { data: userData },
  } = useSelector((state) => state.auth);

  const [userDecisionState, setUserDecision] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [tempListData, setTempListData] = useState();
  const [samplesListData, setSamplesListData] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [filterBy, setFilterBy] = useState({
    Completed: true,
    Future: true,
    Active: true,
    All: false,
  });

  const [filtredList, setFiltredList] = useState({
    Completed: [],
    Future: [],
    Active: [],
  });
  const [filtredArr, setFiltredArr] = useState({
    Completed: [],
    Future: [],
    Active: [],
  });

  const [isShowStatus, setIsShowStatus] = useState({
    Completed: true,
    Future: true,
    Active: true,
    Templates: true,
    Samples: true,
    Shared: true
  });

  const [isAscending, setIsAscending] = useState(true);

  useEffect(() => {
    dispatch(ListSample());
  }, []);

  useEffect(() => {
    dispatch(ListTemplate({ is_allocator: isAllocatorUser }));
    dispatch(GetSharedDecisions());
  }, [isAllocatorUser]);

  useEffect(() => {
    if (!listTemplates) return;
    setTempListData(listTemplates);
  }, [listTemplates]);

  useEffect(() => {
    if (!listSamples) return;
    setSamplesListData(listSamples);
  }, [listSamples]);

  useEffect(() => {
    dispatch(GetDecision(false, false));
  }, []);

  useEffect(() => {
    if (!userDecisions) return;
    let res = userDecisions?.sort(
      (a, b) => moment(b.created_at).valueOf() - moment(a.created_at).valueOf()
    );
    setUserDecision([res]);
  }, [userDecisions]);

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [state, setState] = useState({
    activeKey: [],
    openFilter: false,
    openSort: false,
  });

  const { openFilter, openSort } = state;

  document.body.onclick = (e) => {
    handleChange("openFilter", false);
    handleChange("openSort", false);
    e.stopPropagation();
  };
  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const handleCreateDecision = (isNew = true) => {
    // setIsEditModalOpen(true);
    navigate(`/${project}/create-decision`);
  };

  const curentDate = new Date();
  const sortByItem = (sortBy, isAscending, decision) => {
    let item = sortBy;
    if (item === "Name") {
      if (isAscending) {
        return decision?.sort((a, b) => a?.problem?.localeCompare(b?.problem));
      }
      return decision?.sort((a, b) => b?.problem?.localeCompare(a?.problem));
    }
    if (item === "Startline") {
      if (isAscending) {
        return decision?.sort(
          (a, b) => Date?.parse(a?.startline) - Date?.parse(b?.startline)
        );
      }
      return decision?.sort(
        (a, b) => Date?.parse(b?.startline) - Date?.parse(a?.startline)
      );
    }
    if (item === "Deadline") {
      if (isAscending) {
        return decision?.sort(
          (a, b) => Date?.parse(a?.deadline) - Date?.parse(b?.deadline)
        );
      }
      return decision?.sort(
        (a, b) => Date?.parse(b?.deadline) - Date?.parse(a?.deadline)
      );
    }

    if (item === "Decision Progress") {
      if (isAscending) {
        return (
          decision?.sort(
            (a, b) => Date?.parse(a?.deadline) - Date?.parse(b?.deadline)
          ) || decision
        );
      }
      return (
        decision?.sort(
          (a, b) => Date?.parse(b?.deadline) - Date?.parse(a?.deadline)
        ) || decision
      );
    }
  };

  const { width } = useWindowSize();

  useEffect(() => {
    filterDecision();
  }, [filterBy, userDecisions]);

  const filterDecision = () => {
    let active = [];
    let completed = [];
    let future = [];

    if (filterBy.Active) {
      if (sortBy) {
        let items = sortByItem(sortBy, isAscending, userDecisions)?.filter(
          (d) =>
            Date.parse(d.startline) - curentDate.getTime() ===
            Date.parse(d.deadline) - curentDate.getTime() &&
            !d?.final_option?.id
        );
        active = items;
        setFiltredList({
          Future: future,
          Completed: completed,
          Active: active,
        });
        setFiltredArr({
          Future: future,
          Completed: completed,
          Active: active,
        });
      }
      active = userDecisions?.filter(
        (d) =>
          Date.parse(d.startline) <= curentDate.getTime() &&
          !d?.final_option?.id
      );
      setFiltredList({ Future: future, Completed: completed, Active: active });
      setFiltredArr({ Future: future, Completed: completed, Active: active });
    }
    if (!filterBy?.Active && filtredList?.Active?.length) {
      setFiltredList({
        ...filtredList,
        Active: [],
        Completed: completed,
        Future: future,
      });
      setFiltredArr({
        ...filtredList,
        Active: [],
        Completed: completed,
        Future: future,
      });
    }
    if (filterBy["Completed"]) {
      if (sortBy) {
        let items = sortByItem(sortBy, isAscending, userDecisions)?.filter(
          (item) => item?.final_option?.id
        );
        completed = items;
        setFiltredList({
          Future: future,
          Completed: completed,
          Active: active,
        });
        setFiltredArr({
          Future: future,
          Completed: completed,
          Active: active,
        });
      }
      completed = userDecisions?.filter((item) => item?.final_option?.id);
      setFiltredList({
        Future: future,
        Completed: completed,
        Active: active,
      });
      setFiltredArr({
        Future: future,
        Completed: completed,
        Active: active,
      });
    }

    if (!filterBy["Completed"] && filtredList.Completed.length) {
      setFiltredList({ Active: active, Future: future, Completed: [] });
      setFiltredArr({ Active: active, Future: future, Completed: [] });
    }
    if (filterBy.Future) {
      if (sortBy) {
        let items = sortByItem(sortBy, isAscending, userDecisions)?.filter(
          (d) =>
            Date.parse(d.startline) - curentDate.getTime() !==
            Date.parse(d.deadline) - curentDate.getTime() &&
            !d?.final_option?.id
        );
        future = items;
        setFiltredList({
          Future: future,
          Completed: completed,
          Active: active,
        });
        setFiltredArr({
          Future: future,
          Completed: completed,
          Active: active,
        });
      }

      future = userDecisions?.filter((d) => {
        let startlineDate = new Date(d.startline);
        let currentDate = new Date();

        // Set hours, minutes, seconds, and milliseconds to 0 to compare only dates
        startlineDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);
        return startlineDate > currentDate && !d?.final_option?.id;
      });

      setFiltredList({ Future: future, Completed: completed, Active: active });
      setFiltredArr({ Future: future, Completed: completed, Active: active });
    }
    if (!filterBy.Future && filtredList.Future.length) {
      setFiltredList({ Active: active, Completed: completed, Future: [] });
      setFiltredArr({ Active: active, Completed: completed, Future: [] });
    }
    if (filterBy.All) {
      if (sortBy) {
        let items = sortByItem(sortBy, isAscending, userDecisions);
        setFiltredList([...items]);
        setFiltredArr([...items]);
      }
      setFiltredList({ Completed: [], Future: [], Active: [] });
      setFiltredArr({ Completed: [], Future: [], Active: [] });
    }
  };

  // const DecisionTemplate = ({ onPreview }) => (
  //   <div className={styles.decision_templates}>
  //     <div className={styles.decision_gradient}></div>
  //     <div className={styles.decision_header}>
  //       <div className={styles.title}>
  //         <h5>Decision Template 1</h5>
  //         <a href="#">Domain/Area/Category</a>
  //       </div>
  //       <div className={styles.preview}>
  //         <a href="#" onClick={onPreview}>
  //           PREVIEW
  //         </a>
  //       </div>
  //     </div>
  //     <div className={styles.decision_addition}>
  //       <span>+</span>
  //     </div>
  //   </div>
  // );
  const [inpVal, setInpVal] = useState("");

  // const filterData = (problem) => {
  //   setInpVal(problem);
  //   const copyItems = { ...filtredList };

  //   if (problem.length === 0) {
  //     setFiltredList(filtredArr);
  //   } else {
  //     const filteredData = {};
  //     Object.keys(copyItems).forEach((key) => {
  //       const filteredArray = copyItems[key].filter((item) => {
  //         const Problem = item.problem
  //           ? item.problem
  //               .toString()
  //               .toLowerCase()
  //               .includes(problem.toLowerCase())
  //           : false;

  //         return Problem;
  //       });
  //       if (filteredArray.length > 0) {
  //         filteredData[key] = filteredArray;
  //       }
  //     });

  //     setFiltredList(filteredData);
  //     return filteredData;
  //   }
  // };

  const getFilteredProblem = (data) => {
    return data?.filter((item) =>
      item?.problem?.toString()?.toLowerCase()?.includes(inpVal?.toLowerCase())
    );
  };

  const handleSort = () => {
    filterDecision();
  }

  return (
    <DecisionMatrixLayout>
      {isEditModalOpen && (
        <EditDecisionModal
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          decision={""}
        />
      )}

      <HelpPopup />

      <div>
        <div
          className={cc(styles.wrapper, {
            [styles.hiden]: pathname.includes("create_decision"),
          })}
        >
          {width > 1199 && (
            <div
              className={styles.header}
              style={{
                backgroundImage: `url(https://res.cloudinary.com/talaash/image/upload/v1676390506/Header_ws5ree.png)`,
              }}
            >
              <Col span={8}>
                <Row align="middle" className={styles.titleWrapper}>
                  <h2 className={styles.title}>Decisions Dashboard</h2>

                  <button
                    className={styles.create}
                    onClick={() => handleCreateDecision()}
                    type="button"
                  >
                    <AddIcon />
                    {width > 1199 ? "CREATE NEW DECISION" : "CREATE NEW"}
                  </button>

                  {selectedStatus?.length > 0 && (
                    <Row>
                      <ExpandIcon className={"ml-1"} />
                    </Row>
                  )}
                </Row>
              </Col>

              {/* <Col span={8} className="px-5">
                <div className="d-flex justify-content-center">
                  <div className={styles.searchInput}>
                   
                    <input
                      type="text"
                      placeholder="Search"
                      value={inpVal}
                      onChange={(e) => setInpVal(e.target.value)}
                    />
                    <SearchIcon style={{ cursor: "pointer" }} />
                  </div>
                </div>
              </Col> */}

              {loading && <AppLoading style={{ justifyContent: "center" }} />}

              <Col span={8}>
                <Row
                  className={styles.sort}
                  style={{ justifyContent: "flex-end" }}
                >
                  <Col span={12}></Col>
                  <Row align="middle">
                    <>
                      <SortIcon style={{ transform: "translateX(7px)" }} />
                      <Select
                        className="mr-1 drowpdown"
                        placeholder="SORT"
                        open={openSort}
                        onClick={(e) => {
                          handleChange("openFilter", false);
                          handleChange("openSort", !openSort);
                          e.stopPropagation();
                        }}
                        dropdownMatchSelectWidth={false}
                        dropdownRender={(menu) => (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Sort
                              handleChange={handleChange}
                              isAscending={isAscending}
                              setIsAscending={setIsAscending}
                              sortBy={sortBy}
                              setSortBy={setSortBy}
                              setSortedList={setSortedList}
                              setDecision={setUserDecision}
                              decision={userDecisionState}
                              handleSort={handleSort}
                            />
                          </div>
                        )}
                      ></Select>
                    </>
                    <>
                      <FilterIcon style={{ transform: "translateX(7px)" }} />
                      <Select
                        className="mr-1"
                        open={openFilter}
                        onClick={(e) => {
                          handleChange("openSort", false);
                          handleChange("openFilter", !openFilter);
                          e.stopPropagation();
                        }}
                        placeholder="FILTER"
                        dropdownMatchSelectWidth={false}
                        dropdownRender={(menu) => (
                          <div
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Filter
                              setSortedList={setSortedList}
                              handleChange={handleChange}
                              isAscending={isAscending}
                              setIsAscending={isAscending}
                              allDecisions={userDecisions}
                              decision={userDecisionState}
                              setDecision={setUserDecision}
                              sortedList={sortedList}
                              sortBy={sortBy}
                              filterBy={filterBy}
                              setFilterBy={setFilterBy}
                              setFiltredList={setFiltredList}
                              filtredList={filtredList}
                            />
                          </div>
                        )}
                      ></Select>
                    </>
                  </Row>
                </Row>
              </Col>
            </div>
          )}
          {width < 1200 && (
            <div className={styles.header}>
              <div className={styles.titleWrapper}>
                <h2 className={styles.title}>Decisions Dashboard</h2>

                <button
                  className={styles.create}
                  onClick={() => handleCreateDecision()}
                  type="button"
                >
                  {width > 1199 ? "+CREATE NEW DECISION" : "+CREATE NEW"}
                </button>

                {selectedStatus?.length > 0 && (
                  <Row>
                    <ExpandIcon className={"ml-1"} />
                  </Row>
                )}
              </div>

              {loading && <AppLoading />}

              <div className={styles.sort}>
                <Select
                  className="mr-1 drowpdown"
                  placeholder="SORT"
                  open={openSort}
                  onClick={(e) => {
                    handleChange("openFilter", false);
                    handleChange("openSort", !openSort);
                    e.stopPropagation();
                  }}
                  dropdownMatchSelectWidth={false}
                  dropdownRender={(menu) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Sort
                        handleChange={handleChange}
                        isAscending={isAscending}
                        setIsAscending={setIsAscending}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        setSortedList={setSortedList}
                        setDecision={setUserDecision}
                        decision={userDecisionState}
                        handleSort={handleSort}
                      />
                    </div>
                  )}
                ></Select>
                <Select
                  className="mr-1"
                  open={openFilter}
                  onClick={(e) => {
                    handleChange("openSort", false);
                    handleChange("openFilter", !openFilter);
                    e.stopPropagation();
                  }}
                  placeholder="FILTER"
                  dropdownMatchSelectWidth={false}
                  dropdownRender={(menu) => (
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Filter
                        setSortedList={setSortedList}
                        handleChange={handleChange}
                        isAscending={isAscending}
                        setIsAscending={isAscending}
                        allDecisions={userDecisions}
                        decision={userDecisionState}
                        setDecision={setUserDecision}
                        sortedList={sortedList}
                        sortBy={sortBy}
                        filterBy={filterBy}
                        setFilterBy={setFilterBy}
                        setFiltredList={setFiltredList}
                        filtredList={filtredList}
                      />
                    </div>
                  )}
                ></Select>
              </div>
            </div>
          )}

          {!filterBy?.Completed && !filterBy?.Active && !filterBy?.Future && (
            <div className={styles.decisions}>
              {userDecisionState?.map(
                (decision) =>
                  decision?.id && (
                    <DecisionCard key={decision?.id} decision={decision} forAllocator={isAllocatorUser} />
                  )
              )}
            </div>
          )}

          <div className={styles.statusesWrapper}>
            {getFilteredProblem(filtredList?.Active)?.length &&
              filtredList?.Active[0]?.id ? (
              <div>
                <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                  <span>{isAllocatorUser ? "My Decisions" : "Active Decisions"} ({filtredList?.Active?.length})</span>
                  <ArrowIcon
                    onClick={() =>
                      setIsShowStatus({
                        ...isShowStatus,
                        Active: !isShowStatus?.Active,
                      })
                    }
                    style={{
                      transform: !isShowStatus.Active && `rotate(-180deg)`,
                    }}
                    className={styles.iconShow}
                    color={isAllocatorUser ? "#112538" : "#ffffff"}
                  />
                </div>
                {isShowStatus.Active && (
                  <div className={styles.decisions}>
                    {getFilteredProblem(filtredList?.Active).map(
                      (decision) =>
                        decision?.id && (
                          <DecisionCard
                            key={decision?.id}
                            decision={decision}
                            forAllocator={isAllocatorUser}
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
            {getFilteredProblem(filtredList?.Completed)?.length &&
              filtredList?.Completed[0]?.id ? (
              <div>
                <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                  <span>
                    Completed Decisions ({filtredList?.Completed?.length})
                  </span>

                  <ArrowIcon
                    style={{
                      transform: !isShowStatus?.Completed && `rotate(-180deg)`,
                    }}
                    className={styles.iconShow}
                    onClick={() =>
                      setIsShowStatus({
                        ...isShowStatus,
                        Completed: !isShowStatus?.Completed,
                      })
                    }
                    color={isAllocatorUser ? "#112538" : "#ffffff"}
                  />
                </div>
                {isShowStatus?.Completed && (
                  <div className={styles.decisions}>
                    {getFilteredProblem(filtredList?.Completed)?.map(
                      (decision) =>
                        decision?.id && (
                          <DecisionCard
                            key={decision?.id}
                            decision={decision}
                            forAllocator={isAllocatorUser}
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {(isAllocatorUser && sharedDecisions?.length) ? <div>
              <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                <span>
                  Shared with me ({sharedDecisions?.length})
                </span>

                <ArrowIcon
                  style={{
                    transform: !isShowStatus?.Shared && `rotate(-180deg)`,
                  }}
                  className={styles.iconShow}
                  onClick={() =>
                    setIsShowStatus({
                      ...isShowStatus,
                      Shared: !isShowStatus?.Shared,
                    })
                  }
                  color={isAllocatorUser ? "#112538" : "#ffffff"}
                />
              </div>
              {isShowStatus?.Shared && (
                <div className={styles.decisions}>
                  {sharedDecisions.map(
                    (decision) =>
                      decision?.id && (
                        <DecisionCard
                          key={decision?.id}
                          decision={decision}
                          readOnly
                          hideOptions
                        />
                      )
                  )}
                </div>
              )}
            </div> : null}

            {getFilteredProblem(filtredList?.Future)?.length &&
              filtredList?.Future[0]?.id ? (
              <div>
                <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                  <span>Future Decisions({filtredList?.Future?.length})</span>
                  <ArrowIcon
                    style={{
                      transform: !isShowStatus?.Future && `rotate(-180deg)`,
                    }}
                    className={styles.iconShow}
                    onClick={() =>
                      setIsShowStatus({
                        ...isShowStatus,
                        Future: !isShowStatus?.Future,
                      })
                    }
                    color={isAllocatorUser ? "#112538" : "#ffffff"}
                  />
                </div>
                {isShowStatus.Future && (
                  <div className={styles.decisions}>
                    {getFilteredProblem(filtredList?.Future)?.map(
                      (decision) =>
                        decision?.id && (
                          <DecisionCard
                            key={decision?.id}
                            decision={decision}
                            forAllocator={isAllocatorUser}
                          />
                        )
                    )}
                  </div>
                )}
              </div>
            ) : (
              ""
            )}

            {/* {(samplesListData?.length || tempListData?.length) && (
              <h1 className={styles.gallery}>Decisions Gallery</h1>
            )} */}
            {/*  */}

            {isAllocatorUser ? null : (<div>
              {(samplesListData?.length || tempListData?.length) && (
                <h1 className={styles.gallery}>Decisions Gallery</h1>
              )}

              <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                <span>Example Decision ({samplesListData?.length})</span>
                <ArrowIcon
                  className={styles.iconShow}
                  style={{
                    transform: !isShowStatus?.Samples && `rotate(-180deg)`,
                  }}
                  onClick={() =>
                    setIsShowStatus({
                      ...isShowStatus,
                      Samples: !isShowStatus?.Samples,
                    })
                  }
                  color={isAllocatorUser ? "#112538" : "#ffffff"}
                />
              </div>

              {isShowStatus?.Samples && (
                <div className={styles.decision_container}>
                  {samplesListData?.map((item, i) => (
                    <DecisionSampleCard
                      key={i}
                      decision={item}
                      isAdmin={userData.is_superuser}
                    />
                  ))}
                </div>
              )}
            </div>)}

            {/*  */}

            {/*  */}
            <div>
              <div className={cc(styles.statuses, {[styles.dark]: isAllocatorUser})}>
                <span>{isAllocatorUser ? "My Templates" : "Decision Templates"} ({tempListData?.length})</span>
                <ArrowIcon
                  className={styles.iconShow}
                  style={{
                    transform: !isShowStatus?.Templates && `rotate(-180deg)`,
                  }}
                  onClick={() =>
                    setIsShowStatus({
                      ...isShowStatus,
                      Templates: !isShowStatus?.Templates,
                    })
                  }
                  color={isAllocatorUser ? "#112538" : "#ffffff"}
                />
              </div>
              {isShowStatus?.Templates && (
                <div className={styles.decision_container}>
                  {tempListData?.map((item, i) => (
                    <DecisionTemplate key={i} template={item} forAllocator={isAllocatorUser} />
                  ))}
                </div>
              )}
            </div>
            {/*  */}
          </div>
        </div>
        <div
          className={cc(styles.outlet, {
            [styles.active]: pathname.includes("create_decision"),
          })}
        >
          <Outlet />
        </div>
      </div>
    </DecisionMatrixLayout>
  );
};

export { DecisionMatrix };
