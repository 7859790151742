import moment from "moment";
import { Progress, Tooltip } from "antd";
import { COLORS } from "constants/colors";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import DeleteModal from "scenes/PlanDetails/Modal/DeleteModal";
import { archivePlan, removePlan } from "modules/actions/PlanActions";
import { useDispatch, useSelector } from "react-redux";
import styles from "./styles.module.scss";
import RenamePlanModal from "scenes/PlanDetails/Modal/RenamePlanModal";

export default function PlanCard({
  item,
  loadingUpdatePlan,
  handleUpdatePlan,
  isHierarchy,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { loadingDelete, loadingArchive } = useSelector(
    (state) => state.plan.plan
  );

  const { users } = useSelector((state) => state.user.projectOwners);

  const [state, setState] = useState({
    deleteModal: false,
    renamePlanModal: false,
    deleteArchiveModal: false,
    planName: item?.name || "",
  });

  const { deleteModal, renamePlanModal, planName, deleteArchiveModal } = state;

  const handleArchive = () => {
    const payload = {
      project_plan: parseInt(item?.id),
      is_archived: !item?.is_archived,
    };

    dispatch(archivePlan(payload, goBack));
  };

  const handleChange = (key, value) => {
    setState((pre) => ({ ...pre, [key]: value }));
  };

  const goBack = () => {
    handleChange("deleteModal", false);
    handleChange("renamePlanModal", false);
    handleChange("deleteArchiveModal", false);
  };

  const handleRemove = (e) => {
    dispatch(removePlan(item?.id, goBack));
  };

  const onClickHandle = (event) => {
    if (!item?.is_archived) {
      if (
        !event.target.closest(".ant-dropdown") &&
        !event.target.closest("#test")
      )
        navigate(
          `/project-planner/plan-details/${item?.id}/?hierarchy=${isHierarchy}`
        );
    }
  };

  const deadLine = moment(item?.dead_line).isSameOrAfter()
    ? "Due " + moment(item?.dead_line).fromNow()
    : moment(item?.dead_line).fromNow();

  const goalProgress = Number(item?.goal_progress * 100 || 0).toFixed(0);
  const planProgress = Number(item?.plan_progress * 100 || 0).toFixed(0);

  return (
    <>
      <div onClick={onClickHandle} className={styles.card} test-id="card">
        <div className={styles.head}>
          <Tooltip title={item?.name}>
            <h2 className={styles.title}>{item?.name}</h2>
          </Tooltip>
          <div className={styles.actions} id="test">
            <div className="greyBox">
              {users
                .find((user) => user.id === (item?.owner?.id || item?.owner))
                ?.username?.slice(0, 2) ||
                users
                  .find((user) => user.id === (item?.owner?.id || item?.owner))
                  ?.email?.slice(0, 2)}
            </div>

            {/* <Dropdown
              placement="bottomRight"
              click
              className={styles.menuDots}
              menu={getMenuProps(item)}
              trigger={["click"]}
            >
              <Dots />
            </Dropdown> */}
          </div>
        </div>

        <div className="progressBox">
          <div className={styles.progressWrapper}>
            <div className={styles.head}>
              <div className={styles.title}>Goal Progress</div>
              <div className={styles.text}>
                {Number((item?.goal_progress || 0) * 100).toFixed(0)}%
              </div>
            </div>
            <Progress
              percent={goalProgress}
              strokeColor={
                goalProgress >= 70
                  ? COLORS.sliderGreen
                  : goalProgress >= 30
                  ? COLORS.orange
                  : COLORS.red
              }
              style={{ width: "100%" }}
              showInfo={false}
            />
          </div>

          <div className={styles.progressWrapper}>
            <div className={styles.head}>
              <div className={styles.title}>Plan progress</div>
              <div className={styles.text}>
                {Number((item?.plan_progress || 0) * 100).toFixed(0)}%
              </div>
            </div>
            <Progress
              percent={Number(item?.plan_progress * 100 || 0).toFixed(0)}
              strokeColor={
                planProgress >= 70
                  ? COLORS.sliderGreen
                  : planProgress >= 30
                  ? COLORS.orange
                  : COLORS.red
              }
              style={{ width: "100%" }}
              showInfo={false}
            />
          </div>
        </div>

        <div className={styles.footer}>
          <span className={styles.update}>
            Last update: {moment(item?.updated_at).fromNow()}
          </span>
          <span
            className={styles.deadline}
            style={{
              color: deadLine == "Due in 1 day" ? "#F00000" : "##737373",
            }}
          >
            {deadLine}
          </span>
        </div>
      </div>

      <DeleteModal
        visible={deleteModal}
        heading={"Delete Plan"}
        onClose={() => handleChange("deleteModal", false)}
        descriptions={
          <span>
            Are you sure you want to delete <b>"{item?.name}"</b> project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleArchive={handleArchive}
        handleRemove={handleRemove}
        loadingArchive={loadingArchive}
        loading={loadingDelete}
      />

      <DeleteModal
        visible={deleteArchiveModal}
        isPlan
        item={item}
        heading={item?.is_archived ? "Send To Unarchive" : "Send To Archive"}
        onClose={() => handleChange("deleteArchiveModal", false)}
        descriptions={
          <span>
            Are you sure you want to{" "}
            {item?.is_archived ? "unarchive" : "archive"} <b>"{item?.name}"</b>{" "}
            project plan?
          </span>
        }
        planDetails={item}
        handleChange={handleChange}
        handleRemove={handleArchive}
        // handleArchive={handleArchive}
        loading={loadingArchive}
      />

      <RenamePlanModal
        visible={renamePlanModal}
        planDetails={item}
        handleChange={handleChange}
        handleSubmit={(payload) => handleUpdatePlan(item?.id, payload, goBack)}
        loading={loadingUpdatePlan}
      />
    </>
  );
}
