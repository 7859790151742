import React from "react";
import { Spinner } from "elements";

// CSS
import styles from "./AppLoading.module.scss";

const AppLoading = () => {
  return (
    <div className={styles.centered}>
      <Spinner size={32} />
    </div>
  );
}

export { AppLoading };
