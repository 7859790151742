import styled, { css } from "styled-components";

export const Wrapper = styled.div<{ $color?: string , isShort:boolean }>`
  display: flex;
  flex-direction: column;
  padding-left: 6px;
  position: relative;
  min-width: ${({ isShort }) => (isShort ? '1200px' : '1680px')};


  &::before {
    content: "";
    min-width: 6px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ $color }) => $color && css`
    &::before {
      background: ${$color};
    }
  `}
`;

export const Head = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style-type: none;
  position: sticky;
  top: 44px;
  z-index: 1;

  background-color: #F4F9FB;

  &:hover {
    background-color: #FAFAFA;
  }
`;

const sizes = ["24.60", "8.09", "7.26", "5.77", "5.77", "7.03", "8.40", "8.17", "6.63", "7.66", "7.77"];

let nthChildStyles = '';

for (let i = 0; i < sizes.length; i++) {
  nthChildStyles += `
    &:nth-child(${i + 2}) {
      min-width: ${sizes[i]}%;
    }
  `;
}

export const Col = styled.li<{ $center?: boolean }>`
  display: flex;
  align-items: center;
  border-right: 1px solid var(--Neutral-200, #E5E5E5);
  border-bottom: 1px solid var(--Neutral-200, #E5E5E5);
  padding: 0 12px;
  color: var(--Neutral-900, #171717);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  width: 100%;
  margin: 1px 0px;
  
  ${nthChildStyles};

  ${({ $center }) => $center && css`
    text-align: center;
    justify-content: center;
  `}

  &:nth-child(1) {
    width: 44px;
    min-width: 44px;
    padding: 0;
  }

  &:nth-child(2) {
    padding: 0 12px 0 0;
    min-width: calc(24.60% - 6px);
  }

  &:nth-child(3) {
    padding: 0;
  }
`;

export const WeightContent = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  background: transparent;
  border: none;
  gap: 6px;
`;

export const NameContent = styled.div<{ $disabled: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 20px 0px 20px 20px;

  &:hover {
    .items-actions{
      opacity: 1;
      visibility: visible;
    }
  }

  ${({ $disabled }) => $disabled ? css`` : css`
    &:hover {
      button {
        opacity: 1;
        visibility: visible;
      }
    }
  `}
`;

export const ActionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  opacity: 0;
  visibility: hidden;
`;

export const NameWrapper = styled.div`
  flex: 1;
  /* min-height: 45px; */
  white-space: normal;
  word-break: break-word;

  input {
    height: 100%;
    padding: 13px 12px;
    border-radius: 0;
    margin-right: 12px;
  }

  span {
    display: block;
    /* padding: 12px 0 12px 12px; */
    cursor: default;
    font-weight: 600;
    font-size: 15px;
    color: #112538;
    font-family: "Inter";
  }
`;

export const CollapseWrapper = styled.div`
  
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AddActionItemBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background: none;
  border: none;
  padding: 0;
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px;
  text-transform: uppercase;
  transition: opacity 0.3s, visibility 0.3s;
  color: #1271A6;
  /* opacity: 0; */
  /* visibility: hidden; */


`;

export const Toggle = styled.button<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  padding: 0;
  transform: rotate(180deg);
  transition: transform 0.3s;

  ${({ $active }) => $active && css`
    transform: rotate(0deg);
  `}
`;
